import React from "react";
import classes from "./SingleBill.module.css";
import clsx from "clsx";
import { Text } from "../../../../components/common";
const SingleBill = ({ el }) => {
  return (
    <>
      <Text secondary className={clsx(classes.item, classes.serverName)}>
        {el.serverName}
      </Text>
      <Text secondary className={classes.item}>
        {el.cpu} | {el.ram} | {el.companyName}
      </Text>
      <Text secondary className={classes.item}>
        {el.time}
      </Text>
      <Text secondary className={clsx(classes.item, classes.amount)}>
        {el.amount}
      </Text>
    </>
  );
};

export default SingleBill;
