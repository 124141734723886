import React, { useState } from "react";
import { Button, Input, Text } from "../../../../components/common";
import classes from "./Address.module.css";

import Dropdown from "../../../../components/common/Dropdown/Dropdown";
const countries = ["USA", "UK"];
const cities = ["New York", "Alaska "];
const Address = () => {
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("USA");

  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [selectedCity, setSelectedCity] = useState("New York");

  return (
    <div className={classes.allInputs}>
      <div className={classes.dropDownContainer}>
        <Text textTeritary>Country</Text>
        <Dropdown
          items={countries}
          isActive={showCountryDropdown}
          setIsActive={setShowCountryDropdown}
          selectedValue={selectedCountry}
          onSelect={(val) => setSelectedCountry(val)}
        />
      </div>{" "}
      <div className={classes.dropDownContainer}>
        <Text textTeritary>City</Text>
        <Dropdown
          items={cities}
          isActive={showCityDropdown}
          setIsActive={setShowCityDropdown}
          selectedValue={selectedCity}
          onSelect={(val) => setSelectedCity(val)}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text textTeritary>Street</Text>

        <Input
          type="text"
          value={street}
          setValue={setStreet}
          placeholder="Street"
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text textTeritary>Phone Number</Text>

        <Input
          type="text"
          value={zipCode}
          setValue={setZipCode}
          placeholder="Zip Code"
        />
      </div>{" "}
      <div className={classes.inputWrapper}>
        <Text textTeritary></Text>

        <div className={classes.buttonContainer}>
          <Button>Save Changes</Button>
        </div>
      </div>
    </div>
  );
};

export default Address;
