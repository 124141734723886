import React from "react";
import classes from "./HeroSection.module.css";
import SectionHeading from "../../common/SectionHeading/SectionHeading";
import { Button, Heading, Text } from "../../common";
import { FaAngleRight } from "react-icons/fa";
import { heroImg } from "../../../images";
import clsx from "clsx";
import Wrapper from "../../common/Wrapper/Wrapper";

const HeroSection = () => {
  return (
    <Wrapper className={clsx(classes.container)}>
      <div className={classes.infoContainer}>
        <SectionHeading>Scale, performance, and reliability</SectionHeading>
        <Heading xl7 className={classes.heading}>
          Cloud computing on <span className={classes.highlight}> Scalanode </span>{" "}
          Hybrid Cloud
        </Heading>
        <Text xl2 textTeritary className={classes.info}>
          Build, scale and deliver ultra low latency application through our global network of data centers.
        </Text>

        <div className={classes.buttonContainer}>
          {/* if you pass to it will work link Link and if you pass href it will work like <a/> tag if you pass onClick it will work lik button tag */}
          <Button to="/sign-up" btnInfo>
            Sign-up <FaAngleRight />
          </Button>{" "}
          <Button to="/contact-us" infoTransparent>
            Contact Us <FaAngleRight />
          </Button>
        </div>
      </div>
      <img src={heroImg} alt="#" className={classes.img} />
    </Wrapper>
  );
};

export default HeroSection;
