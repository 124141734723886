import React, { useState } from "react";
import classes from "./SupportTickets.module.css";

import { Button, Heading, Text } from "../../components/common";
import { FiPlus } from "react-icons/fi";
import clsx from "clsx";
import Tabs from "../../components/Dashboard/Tabs/Tabs";
import TicketDetailsModal from "../../Modals/SupportTickets/TicketDetails/TicketDetails";
import CreateNewTicketModal from "../../Modals/SupportTickets/CreateNewTicketModal/CreateNewTicketModal";

const SupportTickets = () => {
  const [showTicketDetailsModal, setShowTicketDetailsModal] = useState(false);
  const [showCreateNewTicketModal, setShowCreateNewTicketModal] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState("All Tickets");
  const tabs = ["All Tickets", "Opened", "Solved", "Pending"];
  const tickets = [
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Opened",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Solved",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Pending",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Opened",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Solved",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Pending",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Opened",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Solved",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Pending",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Opened",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Solved",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Pending",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Opened",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Solved",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Pending",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Opened",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Solved",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Pending",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Opened",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Solved",
      view: "",
    },
    {
      tikcetId: "AB123456",
      subject: "Simple and awesome ticket subject here",
      submissionDate: "24-10-2023",
      status: "Pending",
      view: "",
    },
  ];

  const filterTickets = () => {
    if (selectedTab === "All Tickets") {
      return tickets;
    } else {
      return tickets.filter((ticket) => ticket.status === selectedTab);
    }
  };

  const filteredTickets = filterTickets();

  return (
    <>
      <section className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.titleAndInfo}>
            <Heading xl2>Support Tickets</Heading>
            <Text textTeritary>
              <span className={classes.numberOfTickets}>
                {filteredTickets.length}
              </span>{" "}
              tickets found
            </Text>
          </div>
          <Button
            className={classes.button}
            onClick={() => setShowCreateNewTicketModal(true)}
          >
            Create New Ticket
            <FiPlus className={classes.plusIcon} />
          </Button>
        </div>
        <div className={classes.container}>
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <div className={clsx(classes.allTickets, "overflow")}>
            <Text
              textPlaceholer
              xs
              className={clsx(classes.tableHeading, classes.firstHeading)}
            >
              Ticket ID
            </Text>
            <Text
              textPlaceholer
              xs
              className={clsx(classes.tableHeading, classes.firstHeading)}
            >
              Subject
            </Text>
            <Text textPlaceholer xs className={classes.tableHeading}>
              Submit Date
            </Text>
            <Text textPlaceholer xs className={classes.tableHeading}>
              Status
            </Text>{" "}
            <Text textPlaceholer xs className={classes.tableHeading}></Text>
            {filteredTickets.map((el, i) => (
              <React.Fragment key={i}>
                <Text primary className={clsx(classes.item, classes.id)}>
                  {el.tikcetId}
                </Text>
                <Text primary className={classes.item}>
                  {el.subject}
                </Text>
                <Text primary className={classes.item}>
                  {el.submissionDate}
                </Text>{" "}
                <div className={classes.item}>
                  <Text
                    primary
                    className={clsx(
                      classes.status,
                      el.status.toLowerCase() === "pending" && classes.pending,
                      el.status.toLowerCase() === "opened" && classes.opened,
                      el.status.toLowerCase() === "solved" && classes.solved
                    )}
                  >
                    {el.status}
                  </Text>
                </div>
                <Button
                  onClick={() => setShowTicketDetailsModal(true)}
                  transparent
                  className={clsx(classes.item, classes.view)}
                >
                  View
                </Button>
              </React.Fragment>
            ))}
          </div>{" "}
        </div>
      </section>
      <TicketDetailsModal
        isActive={showTicketDetailsModal}
        onClose={() => setShowTicketDetailsModal(false)}
      />{" "}
      <CreateNewTicketModal
        isActive={showCreateNewTicketModal}
        onClose={() => setShowCreateNewTicketModal(false)}
      />
    </>
  );
};

export default SupportTickets;
