import React, { useState } from "react";
import classes from "./GetStarted.module.css";
import Wrapper from "../../common/Wrapper/Wrapper";
import { Heading, Text } from "../../common";
import { createNewServer } from "../../../images";
import clsx from "clsx";
const GetStarted = () => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      "title": "Signup",
      "info": "Create your account to get started with our platform. It only takes a few minutes.",
    },
    {
      "title": "Deposit Funds",
      "info": "Add funds to your account to unlock all the features and services you need.",
    },
    {
      "title": "Deploy Servers",
      "info": "Easily deploy servers with just a few clicks. Configure them to meet your specific requirements.",
    }

  ];
  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl6>
          Get started <span className={"highlight"}>in minutes</span>
        </Heading>
        <Text xl secondary className={classes.tagline}>
          Quickly set up and launch your projects with our user-friendly platform. No complex configurations required – just sign up, and you're ready to go!
        </Text>
      </div>
      <div className={classes.container}>
        <div className={classes.imgContainer}>
          <img src={createNewServer} alt="#" className={classes.img} />
        </div>
        <div className={classes.steps}>
          {steps?.map((step, i) => (
            <div
              key={i}
              className={clsx(
                classes.step,
                i === activeStep && classes.activeStep
              )}
              onClick={() => setActiveStep(i)}
            >
              <Heading xl2 className={classes.title}>
                <span className={classes.id}>
                  {i + 1 < 9 ? `0${i + 1}` : i + 1}{" "}
                </span>
                {step.title}
              </Heading>
              <Text textTeritary base className={classes.info}>
                {step.info}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default GetStarted;
