import React from "react";
import classes from "./ServersPage.module.css";
import { aws } from "../../images";
import SingleServerDetails from "../../Layout/ServerDetailsLayout/SingleServerDetails/SingleServerDetails";
import { Button, Heading, Text } from "../../components/common";
import { FiPlus } from "react-icons/fi";
const ServersPage = ({ servers }) => {
  // let servers = [
  //   {
  //     project_id: "pid_689829591923",
  //     id: "313350375080",
  //     createdAt: "24 Feb 2024",
  //     name: "s",
  //     region: "ams3",
  //     image: aws,
  //     provider: "DigitalOcean",
  //     specs: {
  //       memory: 2048,
  //       cpu_cores: 2,
  //       primary_storage: 60,
  //     },
  //     server: {
  //       provider: "Digital Ocean",
  //       status: "on",
  //     },
  //     ip: "58.124.84.255",
  //     network_info: null,
  //     private_network_id: "",
  //     provider_id: "427036363",
  //     expires: "2024-08-19 20:27:38.367476 UTC",
  //   },
  //   {
  //     project_id: "pid_689829591923",
  //     id: "313350375080",
  //     createdAt: "24 Feb 2024",
  //     name: "s",
  //     region: "ams3",
  //     image: aws,
  //     provider: "DigitalOcean",
  //     specs: {
  //       memory: 2048,
  //       cpu_cores: 2,
  //       primary_storage: 60,
  //     },
  //     server: {
  //       provider: "Digital Ocean",
  //       status: "on",
  //     },
  //     ip: "58.124.84.255",
  //     network_info: null,
  //     private_network_id: "",
  //     provider_id: "427036363",
  //     expires: "2024-08-19 20:27:38.367476 UTC",
  //   },
  //   {
  //     project_id: "pid_689829591923",
  //     id: "313350375080",
  //     createdAt: "24 Feb 2024",
  //     name: "s",
  //     region: "ams3",
  //     image: aws,
  //     provider: "DigitalOcean",
  //     specs: {
  //       memory: 2048,
  //       cpu_cores: 2,
  //       primary_storage: 60,
  //     },
  //     server: {
  //       provider: "Digital Ocean",
  //       status: "on",
  //     },
  //     ip: "58.124.84.255",
  //     network_info: null,
  //     private_network_id: "",
  //     provider_id: "427036363",
  //     expires: "2024-08-19 20:27:38.367476 UTC",
  //   },
  //   {
  //     project_id: "pid_689829591923",
  //     id: "313350375080",
  //     createdAt: "24 Feb 2024",
  //     name: "s",
  //     region: "ams3",
  //     image: aws,
  //     provider: "DigitalOcean",
  //     specs: {
  //       memory: 2048,
  //       cpu_cores: 2,
  //       primary_storage: 60,
  //     },
  //     server: {
  //       provider: "Digital Ocean",
  //       status: "on",
  //     },
  //     ip: "58.124.84.255",
  //     network_info: null,
  //     private_network_id: "",
  //     provider_id: "427036363",
  //     expires: "2024-08-19 20:27:38.367476 UTC",
  //   },
  //   {
  //     project_id: "pid_689829591923",
  //     id: "313350375080",
  //     createdAt: "24 Feb 2024",
  //     name: "s",
  //     region: "ams3",
  //     image: aws,
  //     provider: "DigitalOcean",
  //     specs: {
  //       memory: 2048,
  //       cpu_cores: 2,
  //       primary_storage: 60,
  //     },
  //     server: {
  //       provider: "Digital Ocean",
  //       status: "on",
  //     },
  //     ip: "58.124.84.255",
  //     network_info: null,
  //     private_network_id: "",
  //     provider_id: "427036363",
  //     expires: "2024-08-19 20:27:38.367476 UTC",
  //   },
  //   {
  //     project_id: "pid_689829591923",
  //     id: "313350375080",
  //     createdAt: "24 Feb 2024",
  //     name: "s",
  //     region: "ams3",
  //     image: aws,
  //     provider: "DigitalOcean",
  //     specs: {
  //       memory: 2048,
  //       cpu_cores: 2,
  //       primary_storage: 60,
  //     },
  //     server: {
  //       provider: "Digital Ocean",
  //       status: "on",
  //     },
  //     ip: "58.124.84.255",
  //     network_info: null,
  //     private_network_id: "",
  //     provider_id: "427036363",
  //     expires: "2024-08-19 20:27:38.367476 UTC",
  //   },
  // ];
  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.titleAndInfo}>
          <Heading xl2>Servers</Heading>
          <Text textTeritary>
            <span className={classes.numberOfServers}>{servers?.length}</span>{" "}
            Server found
          </Text>
        </div>
        <Button className={classes.button}>
          Add New Server
          <FiPlus className={classes.plusIcon} />
        </Button>
      </div>
      <div className={classes.container}>
        {" "}
        {servers?.map((el, i) => (
          <SingleServerDetails {...el} key={i} id={el.id} />
        ))}
      </div>
    </section>
  );
};

export default ServersPage;
