import React, { useState } from "react";
import classes from "./AffiliateProgram.module.css";
import { Button, Counter, Heading, Input, Text } from "../../components/common";
import clsx from "clsx";
import { LuCopy } from "react-icons/lu";

const AffiliateProgram = () => {
  const [searchValue, setSearchValue] = useState("");
  const referedList = [
    { user: "Kelsi Bernier", joined: "2024-03-13", earn: "$20.67" },
    { user: "Cassie Mueller", joined: "2024-03-13", earn: "$18.00" },
    { user: "Alyson Jacobson", joined: "2024-03-27", earn: "$5.12" },
    { user: "Kelsi Bernier", joined: "2024-03-13", earn: "$20.67" },
    { user: "Cassie Mueller", joined: "2024-03-13", earn: "$18.00" },
    { user: "Alyson Jacobson", joined: "2024-03-27", earn: "$5.12" },
    { user: "Kelsi Bernier", joined: "2024-03-13", earn: "$20.67" },
    { user: "Cassie Mueller", joined: "2024-03-13", earn: "$18.00" },
    { user: "Alyson Jacobson", joined: "2024-03-27", earn: "$5.12" },
    { user: "Kelsi Bernier", joined: "2024-03-13", earn: "$20.67" },
    { user: "Cassie Mueller", joined: "2024-03-13", earn: "$18.00" },
    { user: "Alyson Jacobson", joined: "2024-03-27", earn: "$5.12" },
    { user: "Kelsi Bernier", joined: "2024-03-13", earn: "$20.67" },
    { user: "Cassie Mueller", joined: "2024-03-13", earn: "$18.00" },
    { user: "Alyson Jacobson", joined: "2024-03-27", earn: "$5.12" },
    { user: "Kelsi Bernier", joined: "2024-03-13", earn: "$20.67" },
    { user: "Cassie Mueller", joined: "2024-03-13", earn: "$18.00" },
    { user: "Alyson Jacobson", joined: "2024-03-27", earn: "$5.12" },
  ];

  const filterReferredList = () => {
    if (!searchValue) return referedList;
    return referedList.filter((item) =>
      item.user.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const filteredList = filterReferredList();

  return (
    <>
      <div>
        <Heading xl2>Affiliate Program</Heading>
        <Text textTeritary>Welcome to brand_name affiliate program</Text>
      </div>
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <div
            className={clsx(
              classes.infoContainer,
              classes.availableForWithdraw
            )}
          >
            <Text semiBold textTeritary>
              Available for withdraw
            </Text>
            <Heading xl3>
              $<Counter start={0} end={683.92} />
            </Heading>
            <Button btnInfo className={classes.button}>
              Withdraw
            </Button>
          </div>
          <div className={classes.infoContainer}>
            <Text semiBold textTeritary>
              Total Earning
            </Text>
            <Heading xl3>
              $<Counter start={0} end={12395.92} />
            </Heading>
          </div>
          <div className={classes.infoContainer}>
            <Text semiBold textTeritary>
              You Referred
            </Text>
            <Heading xl3>
              <Counter start={0} end={1683} />
            </Heading>
          </div>
          <div className={clsx(classes.infoContainer, classes.referLink)}>
            <Heading base semiBold>
              Refer Link
            </Heading>
            <div className={classes.linkContainer}>
              <a
                href="http://example.com/abc123"
                target="_blank"
                rel="noreferrer"
                className={classes.link}
              >
                http://example.com/abc123
              </a>
              <div className={classes.copyContainer}>
                <LuCopy className={classes.copyIcon} />
              </div>
            </div>
            <Text textTeritary>
              Copy this refer link and share it with your affiliate plan.
            </Text>
          </div>
        </div>
        <div className={clsx(classes.referListContainer, "overflow")}>
          <div className={classes.header}>
            <Heading semiBold base>
              Referred List
            </Heading>
            <Input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
              search
              className={classes.input}
            />
          </div>
          <div className={clsx(classes.referList, "overflow")}>
            <Text
              textTeritary
              xs
              className={clsx(classes.tableHeading, classes.firstHeading)}
            >
              #
            </Text>
            <Text textTeritary xs className={classes.tableHeading}>
              User
            </Text>
            <Text textTeritary xs className={classes.tableHeading}>
              Joined
            </Text>
            <Text
              textTeritary
              xs
              className={clsx(classes.tableHeading, classes.earnHeading)}
            >
              You Earned
            </Text>
            {filteredList.map((el, i) => (
              <React.Fragment key={i}>
                <Text primary className={clsx(classes.item, classes.id)}>
                  {i + 1}
                </Text>
                <Text primary className={classes.item}>
                  {el.user}
                </Text>
                <Text primary className={classes.item}>
                  {el.joined}
                </Text>
                <Text primary className={clsx(classes.item, classes.earn)}>
                  {el.earn}
                </Text>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliateProgram;
