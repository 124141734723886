import axios from "axios";
import Cookies from "js-cookie";
import { act } from "react";
export const getProjectInfo = async (pid) => {
    let api = process.env.REACT_APP_API + "/project/get/" + pid;
    try {
        let { data } = await axios.get(api, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data;
    } catch (e) {

    }
}
export const getUser = async () => {
    let api = process.env.REACT_APP_API + "/user";
    try {
        let { data } = await axios.get(api, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data;
    } catch (e) {

    }
}