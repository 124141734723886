import React, { useState } from "react";

import classes from "./Faqs.module.css";
import { FiMinus, FiPlus } from "react-icons/fi";
import { Heading, Text } from "../../common";
import Wrapper from "../../common/Wrapper/Wrapper";
import clsx from "clsx";

const Faqs = () => {
  const [active, setActive] = useState(3);
  const faqArray = [
    {
      "question": "How do I get started with your cloud services?",
      "answer": "Getting started is easy. Simply sign up for an account, select your preferred plan, and follow our guided setup process to deploy your first server and application."
    },
    {
      "question": "What payment options do you accept?",
      "answer": "We accept all major credit/debit cards, PayPal, and bank transfers. You can also set up automatic payments to ensure your services are never interrupted."
    },
    {
      "question": "How do you ensure the uptime and reliability of your services?",
      "answer": "We guarantee 99.9% uptime through our robust infrastructure, which includes redundant servers, advanced monitoring systems, and regular maintenance schedules to minimize downtime."
    },
    {
      "question": "Can I customize my server configuration?",
      "answer": "Yes, our platform allows you to fully customize your server configuration to meet your specific needs. You can choose the amount of CPU, RAM, and storage, as well as select additional features and software."
    },
    {
      "question": "What is your data backup policy?",
      "answer": "We perform daily backups of all your data and store them securely. In case of data loss, you can easily restore your data from these backups through our user-friendly interface."
    },
    {
      "question": "How do you handle security and compliance?",
      "answer": "We implement industry-standard security measures, including encryption, firewalls, and regular security audits. Our platform complies with major regulations such as GDPR and HIPAA to ensure your data is protected."
    },
    {
      "question": "Is it possible to scale my resources as my business grows?",
      "answer": "Absolutely. Our platform is designed to be highly scalable, allowing you to increase your resources on-demand as your business requirements grow."
    },
    {
      "question": "Do you offer support for migrating existing applications to your cloud?",
      "answer": "Yes, we provide comprehensive support for migrating your existing applications to our cloud. Our team will assist you throughout the process to ensure a smooth transition."
    },
    {
      "question": "What types of applications can I host on your platform?",
      "answer": "You can host a wide range of applications, including web applications, databases, e-commerce platforms, and more. Our platform supports various programming languages and frameworks."
    },
    {
      "question": "How can I monitor the performance of my deployed applications?",
      "answer": "Our platform includes built-in monitoring tools that allow you to track the performance of your applications in real-time. You can view metrics such as CPU usage, memory usage, and network activity."
    },
    {
      "question": "What support options are available if I encounter issues?",
      "answer": "We offer 24/7 customer support through multiple channels, including live chat, email, and phone. Our support team is always ready to assist you with any issues you may encounter."
    }
  ];
  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.header}>
        {" "}
        <Heading xl5>FAQ</Heading>
        <Text>
          Explore our comprehensive FAQ section to find quick answers to common queries about our cloud platform, features, and services. Whether you're just getting started or need assistance with advanced configurations, we've got you covered.
        </Text>
      </div>

      <div className={classes.faqWrapper}>
        {faqArray.map((items, index) => (
          <div
            className={
              active === index
                ? clsx(classes.activeFaqContainer, classes.faqContainer)
                : classes.faqContainer
            }
            key={index}
            onClick={() => setActive(index)}
          >
            <div className={classes.questionWrapper}>
              <Text xl primary semiBold className={classes.question}>
                {items.question}?
              </Text>
              {active === index ? (
                <FiMinus className={classes.icon} />
              ) : (
                <FiPlus className={classes.icon} />
              )}
            </div>
            {active === index && (
              <Text lg textTeritary className={classes.answer}>
                {items.answer}
              </Text>
            )}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Faqs;
