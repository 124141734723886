import React, { useState } from "react";
import classes from "./SignIn.module.css";
import { Button, Heading, Input, Text } from "../../../components/common";
import { githubLogo, googleLogo, linkedinLogo, logo } from "../../../images";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpStage, setOtpStage] = useState(false);

  let navigate = useNavigate();

  const socialLinks = [
    { name: "Github Account", logo: githubLogo, link: "#" },
    { name: "Google Account", logo: googleLogo, link: "#" },
    { name: "Linkedin Account", logo: linkedinLogo, link: "#" },
  ];

  async function loginHandler() {
    setIsLoading(true);
    let api = process.env.REACT_APP_API + "/auth/request-otp";
    let body = {
      method: "Email",
      data: email,
      password: password,
      otp_method: "Email",
    };

    try {
      let { data, status } = await axios.post(api, body);

      if (status === 200) {
        setOtpStage(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function completeLoginHandler() {
    setIsLoading(true);
    let api = process.env.REACT_APP_API + "/auth/login";
    let body = {
      method: "Email",
      data: email,
      password: password,
      mfa: [
        {
          method: "Email",
          data: otp,
        },
      ],
    };

    try {
      let { data, status } = await axios.post(api, body);
      console.log(data);
      Cookies.set("token", data.token);
      if (status === 200) {
        navigate("/dashboard");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <img src={logo} alt="Logo" className={classes.logo} />

        <div className={classes.titleAndInfo}>
          <Heading xl2 semiBold>
            Welcome Back
          </Heading>
          <Text secondary>Login to your account to start</Text>
        </div>
      </div>

      {!otpStage && (
        <div className={classes.container}>
          <div className={classes.inputContainer}>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              setValue={setEmail}
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              setValue={setPassword}
            />
            <div className={classes.buttonContainer}>
              <Button
                onClick={loginHandler}
                loading={isLoading}
                disabled={isLoading}
              >
                Login
              </Button>
              <Link to="/forgot-password" className={classes.forgotPassword}>
                <Text primary sm textRight>
                  Forgot Password?
                </Text>
              </Link>
            </div>
          </div>
          {!otpStage && (
            <>
              <div className={classes.allSocialLinks}>
                {socialLinks?.map((el, i) => (
                  <a
                    key={i}
                    href={el.link}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.socialContainer}
                  >
                    <Text secondary> {el.name}</Text>
                    <img src={el.logo} alt="#" className={classes.socialLogo} />
                  </a>
                ))}
              </div>
              <Text sm textPlaceholer className={classes.or}>
                or
              </Text>
            </>
          )}
        </div>
      )}

      {otpStage && (
        <div className={classes.container}>
          <div className={classes.inputContainer}>
            <Input type="otp" placeholder="OTP" value={otp} setValue={setOtp} />
            <div className={classes.buttonContainer}>
              <Button
                onClick={completeLoginHandler}
                loading={isLoading}
                disabled={isLoading}
              >
                Validate
              </Button>
              <Link to="/forgot-password" className={classes.forgotPassword}>
                <Text primary sm textRight>
                  Forgot Password?
                </Text>
              </Link>
            </div>
          </div>
          <div className={classes.allSocialLinks}>
            {socialLinks?.map((el, i) => (
              <a
                key={i}
                href={el.link}
                target="_blank"
                rel="noreferrer"
                className={classes.socialContainer}
              >
                <Text secondary> {el.name}</Text>
                <img src={el.logo} alt="#" className={classes.socialLogo} />
              </a>
            ))}
          </div>
          <Text sm textPlaceholer className={classes.or}>
            or
          </Text>
        </div>
      )}

      <Text textTeritary className={classes.ask} primaryContent textCenter>
        Don’t have an account?
        <Link to="/sign-up" className={classes.signUp}>
          Sign-up
        </Link>
      </Text>
    </div>
  );
};

export default SignIn;
