import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";

import classes from "./CreateNewTicketModal.module.css";
import Modal from "../../../components/common/Modal/Modal";
import { Input, Button, Text } from "../../../components/common";
import Dropdown from "../../../components/common/Dropdown/Dropdown";

const priorities = ["High", "Low", "Medium"];

const CreateNewTicketModal = ({ onClose, isActive }) => {
  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
  };

  const removeFile = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  return (
    <Modal isActive={isActive} onClose={onClose} className={classes.modal}>
      <div className={classes.header}>
        <Text base base0 semiBold>
          Create New Ticket
        </Text>
        <AiOutlineClose className={classes.closeIcon} onClick={onClose} />
      </div>

      <div className={classes.container}>
        <div className={classes.inputWrapper}>
          <Input
            type="text"
            value={subject}
            setValue={setSubject}
            placeholder="Subject"
          />
          <Dropdown
            items={priorities}
            isActive={showPriorityDropdown}
            setIsActive={setShowPriorityDropdown}
            selectedValue={selectedPriority}
            onSelect={(val) => setSelectedPriority(val)}
            placeholder="Priority"
          />
          <textarea
            className={classes.message}
            name="message"
            id="message"
            rows={3}
            cols={3}
            placeholder="Write your text..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <div className={classes.inputFile}>
          <input
            type="file"
            id="file"
            className={classes.fileInput}
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="file" className={classes.fileLabel}>
            You can attach multiple file
          </label>
          <div className={classes.fileList}>
            {files.map((file, index) => (
              <div key={index} className={classes.fileItem}>
                <FaRegCircleCheck className={classes.checkIcon} />
                <span className={classes.fileName}>{file.name}</span>
                <AiOutlineClose
                  className={classes.removeIcon}
                  onClick={() => removeFile(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <Button transparent className={classes.button} onClick={onClose}>
          Close
        </Button>
        <Button onClick={() => {}}>Create Ticket</Button>
      </div>
    </Modal>
  );
};

export default CreateNewTicketModal;
