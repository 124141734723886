import React, { useEffect, useRef, useState } from "react";
import classes from "./Tabs.module.css";
import clsx from "clsx";

const Tabs = ({ tabs, selectedTab, setSelectedTab }) => {
  const tabRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  useEffect(() => {
    const navbar = tabRef.current;
    const activeElement = navbar.children[activeItem];
    if (activeElement) {
      const containerWidth = navbar.parentElement.offsetWidth;
      const elementWidth = activeElement.offsetWidth;
      const elementLeft = activeElement.offsetLeft;
      const scrollPosition =
        elementLeft - containerWidth / 2 + elementWidth / 2;

      navbar.parentElement.scroll({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeItem]);
  const handleTouchStart = (e) => {
    const touchStart = e.touches[0].clientX;
    const handleTouchMove = (moveEvent) => {
      const touchEnd = moveEvent.touches[0].clientX;
      const delta = touchStart - touchEnd;
      tabRef.current.parentElement.scrollLeft += delta;
      moveEvent.preventDefault();
    };
    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd);
  };
  return (
    <div className={clsx(classes.allTabs)} onTouchStart={handleTouchStart}>
      <div ref={tabRef} className={classes.tabs}>
        {tabs?.map((tab, i) => (
          <button
            onClick={() => {
              setSelectedTab(i);
              setActiveItem(i);
            }}
            className={clsx(
              classes.tab,
              tab.name.toLowerCase() === tabs[selectedTab].name.toLowerCase() &&
                classes.activeTab
            )}
            key={i}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
