import React from "react";
import classes from "./SectionHeading.module.css";
import { Heading } from "..";
const SectionHeading = ({ children }) => {
  return (
    <Heading base primary semiBold className={classes.heading}>
      {children}
    </Heading>
  );
};

export default SectionHeading;
