import React, { useState } from "react";
import Chart from "react-apexcharts";
import "./Chart.css";
import classes from "./Chart.module.css";

const dummySeriesData = {
  monthDataSeries1: {
    prices: [
      100, 150, 300, 80, 90, 250, 280, 400, 250, 310, 700, 600, 550, 150,
    ],
    dates: [
      "2024-01-11",
      "2024-01-12",
      "2024-01-15",
      "2024-01-16",
      "2024-01-17",
      "2024-01-18",
      "2024-01-19",
      "2024-01-22",
      "2024-01-23",
      "2024-01-24",
      "2024-01-25",
      "2024-01-26",
      "2024-01-27",
      "2024-01-28",
    ],
  },
};

const ApexChart = ({ seriesData = dummySeriesData }) => {
  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: "CPU Usage",
        data: seriesData.monthDataSeries1.prices,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 360,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#C1D3F6"], // Ending color
          inverseColors: false,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      labels: seriesData.monthDataSeries1.dates,
      xaxis: {
        type: "datetime",
        labels: {
          style: {
            fontFamily: "Inter",
            colors: "#9AA9BE", // Custom color
            fontSize: "12px",
            fontWeight: "500",
          },
          formatter: function (value) {
            const date = new Date(value);
            return date
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
              })
              .replace(/\//g, "/");
          },
        },
      },
      yaxis: {
        opposite: false,
        labels: {
          style: {
            fontFamily: "Inter",
            colors: "#9AA9BE", // Custom color
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const date = new Date(w.globals.seriesX[seriesIndex][dataPointIndex]);
          const formattedDate = date
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              // year: "numeric",
            })
            .replace(/\//g, "/");
          const percentageUsed = series[seriesIndex][dataPointIndex];
          const ghz = percentageUsed / 100; // Assuming 3.5 GHz max

          return `<div class="tooltip-box">
                    <div class="tooltip-title">Details</div>
                      <div class="tooltip-date">${formattedDate}</div>
                    <div class="tooltip-percentage">${percentageUsed}%</div>
                    <div class="tooltip-ghz">${ghz.toFixed(2)}GHz</div>
                  </div>`;
        },
        x: {
          formatter: function (value) {
            const date = new Date(value);
            return date
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, "/");
          },
          style: {
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "bold",
            color: "#000", // Custom color for the date label in the tooltip
          },
        },
      },
      grid: {
        padding: {
          top: 10, // Adjust the height of the top line
        },
        show: true,
        borderColor: "#e2e8f0",
        strokeDashArray: 1,
        xaxis: {
          lines: {
            show: true, // Show vertical grid lines
          },
        },
        yaxis: {
          lines: {
            show: true, // Show horizontal grid lines
          },
        },
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  });

  return (
    <div id="chart" className={classes.chart}>
      <Chart
        options={chartOptions.options}
        series={chartOptions.series}
        type="area"
        height={360}
      />
    </div>
  );
};

export default ApexChart;
