import React from "react";

import classes from "./Sidebar.module.css";
import { NavLink } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import clsx from "clsx";
import { Text } from "../../../components/common";
import {
  apiKeyIcon,
  apiKeyActiveIcon,
  changePasswordIcon,
  changePasswordActiveIcon,
  deleteAccountIcon,
  deleteAccountActiveIcon,
  userIcon,
  userActiveIcon,
} from "../../../images";

const Sidebar = ({ sidebar, setSidebar }) => {
  const navItems = [
    {
      icons: [userIcon, userActiveIcon],
      navItem: "Accounts",
      to: "/accounts-settings/accounts",
    },
    {
      icons: [changePasswordIcon, changePasswordActiveIcon],
      navItem: "Change Password",
      to: "/accounts-settings/change-password",
    },
    {
      icons: [apiKeyIcon, apiKeyActiveIcon],
      navItem: "API Key",
      to: "/accounts-settings/api-key",
    },
    {
      icons: [deleteAccountIcon, deleteAccountActiveIcon],
      navItem: "Delete Account",
      to: "/accounts-settings/delete-account",
    },
  ];

  return (
    <>
      <section
        className={clsx(classes.sidebar, sidebar && classes.showSidebar)}
        onClick={() => setSidebar(false)}
      >
        <div className={classes.header}>
          <Text xs textPlaceholer>
            Menu
          </Text>
          <AiOutlineClose
            className={classes.closeIcon}
            onClick={() => setSidebar(false)}
          />
        </div>
        <div className={classes.navItems}>
          {navItems.map((el, i) => (
            <NavLink
              to={el.to}
              key={i}
              className={({ isActive }) =>
                isActive
                  ? [classes.navItem, classes.navActive].join(" ")
                  : classes.navItem
              }
              onClick={() => setSidebar((prev) => !prev)}
            >
              {({ isActive, isPending }) => {
                return (
                  <>
                    <img
                      src={isActive ? el.icons[1] : el.icons[0]}
                      alt="#"
                      className={classes.navIcon}
                    />
                    <span>{el.navItem}</span>
                  </>
                );
              }}
            </NavLink>
          ))}
        </div>
      </section>
      {sidebar && (
        <div
          className={classes.overlay}
          onClick={() => setSidebar(false)}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
