import React from "react";
import classes from "./Info.module.css";
import { Button, Counter, Heading, Text } from "../../../components/common";
import { FiPlus } from "react-icons/fi";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const Info = ({ servers, projects, user }) => {
  let navigate = useNavigate();

  console.log(user?.projects);
  return (
    <div className={classes.wrapper}>
      <div className={classes.info}>
        <div className={classes.box}>
          <div className={classes.heading}>
            <Heading xl3>
              <Counter
                start={0}
                end={servers?.length ? servers.length : 0}
                durationTime={20}
              />
            </Heading>
            <Text headingColor>Total Servers</Text>
          </div>
          <Button transparent className={classes.button} onClick={() => { }}>
            Add server
            <FiPlus className={classes.icon} />
          </Button>
        </div>{" "}
        {/* <div className={classes.box}>
          <div className={classes.heading}>
            <Heading xl3>
              <Counter start={0} end={0} durationTime={20} />
            </Heading>
            <Text headingColor>Total Applications</Text>
          </div>
          <Button transparent className={classes.button} onClick={() => { }}>
            Add app
            <FiPlus className={classes.icon} />
          </Button>
        </div>{" "} */}
        <div className={classes.box}>
          <div className={classes.heading}>
            <Heading xl3>
              <Counter
                start={0}
                end={user?.projects ? user?.projects.length : 0}
                durationTime={20}
              />
            </Heading>
            <Text headingColor>Total Projects</Text>
          </div>
          <Button transparent className={classes.button} onClick={() => { }}>
            Add project
            <FiPlus className={classes.icon} />
          </Button>
        </div>{" "}
        {/* <div className={classes.box}>
          <div className={classes.heading}>
            <Heading xl3>
              <Counter start={0} end={0} durationTime={20} />
            </Heading>
            <Text headingColor>Opened Tickets</Text>
          </div>
          <Button transparent className={classes.button} onClick={() => { }}>
            Create new
            <FiPlus className={classes.icon} />
          </Button>
        </div> */}
      </div>
      <div className={classes.freeTrailBox}>
        <div className={classes.freeTrialHeading}>
          <Heading xl>You are on Free Trial</Heading>
          <Text textTeritary>Billing will start after upgrade</Text>
        </div>
        <div className={classes.amountContainer}>
          <div className={classes.perHour}>
            <Heading xl2 textCenter>
              $
              <Counter
                start={0}
                end={projects?.balance ? projects.balance : 0}
                durationTime={10}
              />
            </Heading>
            <Text textTeritary textCenter>
              Balance
            </Text>
          </div>{" "}
          <div className={classes.perMonth}>
            <Heading xl2 textCenter>
              $<Counter start={0} end={0} durationTime={20} />
            </Heading>
            <Text textTeritary textCenter>
              Credit
            </Text>
          </div>
        </div>
        <Button
          wFull
          onClick={() => {
            navigate("/billing/overview");
          }}
        >
          Go to Billing
        </Button>
      </div>
      {/* <div className={classes.resource}>
        <div className={classes.resourceHeading}>
          <Heading base>Resources</Heading>
          <Text textTeritary>Lorem ipsum dolor sit sed do eiusmod.</Text>
        </div>
        <FaAngleRight className={classes.arrow} />
      </div>{" "}
      <div className={classes.whatsNew}>
        <div className={classes.whatsNewHeading}>
          <Heading base baseWhite>
            What’s New
          </Heading>
          <Text baseWhite>Lorem ipsum dolor sit sed do eiusmod.</Text>
        </div>
        <FaAngleRight className={classes.arrow} />
      </div> */}
    </div>
  );
};

export default Info;
