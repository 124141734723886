import React from "react";
import classes from "./ServerDetailsLayout.module.css";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";
import SingleServerDetails from "./SingleServerDetails/SingleServerDetails";
import { aws } from "../../images";
import { Button } from "../../components/common";

const ServerDetailsLayout = () => {
  const server = {
    project_id: "pid_689829591923",
    id: "313350375080",
    createdAt: "24 Feb 2024",
    name: "s",
    region: "ams3",
    image: aws,
    provider: "DigitalOcean",
    specs: {
      memory: 2048,
      cpu_cores: 2,
      primary_storage: 60,
    },
    server: {
      provider: "Digital Ocean",
      status: "on",
    },
    ip: "58.124.84.255",
    network_info: null,
    private_network_id: "",
    provider_id: "427036363",
    expires: "2024-08-19 20:27:38.367476 UTC",
  };
  return (
    <section className={classes.wrapper}>
      <Button to="/servers" transparent className={classes.button}>
        <IoIosArrowBack className={classes.back} /> Servers
      </Button>
      <SingleServerDetails {...server} type2 />
      <div className={classes.container}>
        <Sidebar id={server.id} />
        <Outlet />
      </div>
    </section>
  );
};

export default ServerDetailsLayout;
