import React from "react";
import classes from "./Applications.module.css";
import { Button, Heading, Text } from "../../components/common";
import { FiPlus } from "react-icons/fi";
import { notFound } from "../../images";
const Applications = () => {
  const applications = [];

  const steps = [
    { title: "Setup Server", info: "Initially, you must setup your server." },
    {
      title: "Add Application",
      info: "You must add an application for your project.",
    },
    { title: "Create Project", info: "You must create a project. " },
  ];
  return (
    <>
      <div className={classes.header}>
        <div className={classes.titleAndInfo}>
          <Heading xl2>Applications</Heading>
          <Text textTeritary>
            <span className={classes.numberOfApplications}>
              {applications?.length}
            </span>{" "}
            application found
          </Text>
        </div>
        <Button className={classes.button}>
          Add New Application
          <FiPlus className={classes.plusIcon} />
        </Button>
      </div>
      <div className={classes.container}>
        <div className={classes.infoWrapper}>
          <img src={notFound} alt="#" className={classes.img} />{" "}
          <div className={classes.infoContainer}>
            <Heading xl2 textCenter>
              No Application Found
            </Heading>
            <Text secondary textCenter>
              You have no application yet. Please create a new server and
              install application.
            </Text>
          </div>{" "}
          <Button onClick={() => {}} btnSuccess>
            Create Application
          </Button>
        </div>
        <div className={classes.stepsContainer}>
          <Heading semiBold lg font600>
            Start your journey in three step
          </Heading>
          <div className={classes.steps}>
            {steps.map((step, i) => (
              <div className={classes.step}>
                <Text className={classes.id}>{i + 1}</Text>
                <div className={classes.infoBox}>
                  <Heading semiBold base>
                    {step.title}
                  </Heading>
                  <Text secondary>{step.info}</Text>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Applications;
