import React, { useState } from "react";
import classes from "./BillingHistory.module.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Heading, Text } from "../../../components/common";
import clsx from "clsx";
import SingleBill from "./SingleBill/SingleBill";

const BillingHistory = () => {
  const [showBillDetails, setShowBillDetails] = useState(1);
  const allBills = [
    {
      date: "21-Feb-2024",
      paymentType: "Auto-payment",
      price: "$160.00",
      status: "Completed",
      billSummary: [
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
      ],
    },
    {
      date: "21-Feb-2024",
      paymentType: "Direct-payment",
      price: "$160.00",
      status: "Completed",
      billSummary: [
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
      ],
    },
    {
      date: "21-Feb-2024",
      paymentType: "Auto-payment",
      price: "$160.00",
      status: "Completed",
      billSummary: [
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
      ],
    },
    {
      date: "21-Feb-2024",
      paymentType: "Direct-payment",
      price: "$160.00",
      status: "Completed",
      billSummary: [
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
        {
          serverName: "Server Name Here",
          cpu: "2vcpu",
          ram: "4gb",
          companyName: "Intel",
          time: "80 Hours",
          amount: "$8.00",
        },
      ],
    },
  ];
  const calculatingTotal = (arr) => {
    return arr.reduce((acc, item) => {
      const amount = parseFloat(item.amount.replace("$", ""));
      return acc + amount;
    }, 0);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading lg>Billing History</Heading>
        <Text textTeritary className={classes.info}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Text>
      </div>
      <div className={classes.billSummaryContainer}>
        <div className={clsx(classes.allBills, "overflow")}>
          {allBills?.map((el, id) => (
            <div
              key={id}
              className={clsx(
                showBillDetails !== id + 1 && classes.border,
                classes.billSummary
              )}
              onClick={() => {
                if (showBillDetails === id + 1) {
                  setShowBillDetails(null);
                } else {
                  setShowBillDetails(id + 1);
                }
              }}
            >
              <React.Fragment>
                <Text
                  primary
                  semiBold
                  className={clsx(classes.item, classes.date)}
                >
                  {el.date}
                </Text>
                <Text primary semiBold className={classes.item}>
                  {el.paymentType}
                </Text>
                <Text primary semiBold className={classes.item}>
                  {el.price}
                </Text>
                <div className={classes.statusContainer}>
                  <Text
                    primary
                    semiBold
                    className={clsx(
                      classes.status,
                      el.status.toLowerCase() === "completed" &&
                        classes.completed,
                      el.status.toLowerCase() === "pending" && classes.pending
                    )}
                  >
                    {el.status}
                  </Text>
                  {showBillDetails === id + 1 ? (
                    <FaChevronUp
                      className={classes.arrow}
                      onClick={() => {
                        if (showBillDetails === id + 1) {
                          setShowBillDetails(null);
                        } else {
                          setShowBillDetails(id + 1);
                        }
                      }}
                    />
                  ) : (
                    <FaChevronDown
                      className={classes.arrow}
                      onClick={() => {
                        if (showBillDetails === id + 1) {
                          setShowBillDetails(null);
                        } else {
                          setShowBillDetails(id + 1);
                        }
                      }}
                    />
                  )}
                </div>
              </React.Fragment>
              {showBillDetails === id + 1 && (
                <>
                  {el.billSummary.map((element, i) => (
                    <React.Fragment key={i}>
                      <SingleBill el={element} />
                    </React.Fragment>
                  ))}
                  <Text secondary></Text>
                  <Text secondary></Text>
                  <Text primary font600 className={clsx(classes.item)}>
                    Total
                  </Text>
                  <Text
                    primary
                    font600
                    className={clsx(classes.item, classes.amount)}
                  >
                    ${calculatingTotal(el.billSummary).toFixed(2)}
                  </Text>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;
