import React from "react";
import classes from "./CreateSeverAndHostProject.module.css";
import Wrapper from "../../common/Wrapper/Wrapper";
import { Button, Heading, Text } from "../../common";
import { FaAngleRight } from "react-icons/fa";

import {
  costOptimization,
  deployment,
  restore,
  scalibility,
  securityIcon,
  support,
} from "../../../images";
const CreateSeverAndHostProject = () => {
  const data = [
    {
      icon: scalibility,
      title: "Automated Scalability",
      info: "Automatically scale resources (CPU, RAM, storage) based on demand. Ensure optimal performance during traffic spikes without manual intervention.",
      to: "/sign-up",
    },
    {
      icon: support,
      title: "24/7 Support",
      info: "Continuous monitoring of server and application performance. Immediate alerts and proactive troubleshooting by a dedicated support team.",
    },
    {
      icon: restore,
      title: "Restore & Backup",
      info: "Scheduled backups of data to secure locations. Disaster recovery plans and processes to minimize downtime in case of failures.",
    },
    {
      icon: securityIcon,
      title: "Security Management",
      info: "Regular security audits and updates to protect against vulnerabilities. Implementation of firewalls, DDoS protection, and encryption protocols.",
    },
    {
      icon: deployment,
      title: "Application Deployment Automation",
      info: "Streamlined deployment processes for applications and updates. Integration with CI/CD pipelines for continuous delivery.",
    },

    {
      icon: costOptimization,
      title: "Cost Optimization",
      info: "Monitoring and optimization of resource usage to minimize costs.",
    },
  ];
  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl6>
          Create your server and host your project,{" "}
          <span className={"highlight"}>we'll handle the rest </span>
        </Heading>
        <Text xl secondary className={classes.tagline}>
          Effortlessly set up your server and deploy your projects with ease. Our platform manages all the underlying complexities, allowing you to focus on innovation and growth.
        </Text>
      </div>
      <div className={classes.container}>
        {data.map((el, i) => (
          <div className={classes.feature} key={i}>
            <img src={el.icon} alt="#" className={classes.icon} />
            <Heading xl2 className={classes.title}>
              {el.title}
            </Heading>
            <Text textTeritary base className={classes.info}>
              {el.info}
            </Text>
            {el.to && (
              <Button to={el.to} btnInfo>
                Start For Free <FaAngleRight />
              </Button>
            )}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default CreateSeverAndHostProject;
