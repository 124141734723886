import React, { useState } from "react";
import classes from "./AccountSettings.module.css";

import Modal from "../../components/common/Modal/Modal";

import { Routes, Route } from "react-router-dom";
import Accounts from "./Accounts/Accounts";
import ChangePassword from "./ChangePassword/ChangePassword";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import Sidebar from "../../Layout/AccountSettings/Sidebar/Sidebar";
import ApiKey from "./ApiKey/ApiKey";

const AccountSettings = () => {
  const [sidebar, setSidebar] = useState(false);
  const [showModal, setShowModal] = useState(true);

  return (
    <Modal
      isActive={showModal}
      heading="Accounts"
      onClose={() => setShowModal(false)}
    >
      <div className={classes.wrapper}>
        <Sidebar setSidebar={setSidebar} sidebar={sidebar} />

        <div className={classes.container}>
          <Routes>
            <Route
              path="/accounts"
              element={
                <Accounts setShowModal={setShowModal} setSidebar={setSidebar} />
              }
            />
            <Route
              path="/change-password"
              element={
                <ChangePassword
                  setShowModal={setShowModal}
                  setSidebar={setSidebar}
                />
              }
            />{" "}
            <Route
              path="/api-key"
              element={
                <ApiKey setShowModal={setShowModal} setSidebar={setSidebar} />
              }
            />
            <Route
              path="/delete-account"
              element={
                <DeleteAccount
                  setShowModal={setShowModal}
                  setSidebar={setSidebar}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </Modal>
  );
};

export default AccountSettings;
