import React from "react";
import classes from "./Features.module.css";
import { Heading, Text } from "../../common";
import clsx from "clsx";
import Wrapper from "../../common/Wrapper/Wrapper";

const Features = () => {
  const features = [
    {
      title: "Enterprise Grade Security",
      info: "Protect your business with advanced security measures designed to prevent disruptions and safeguard your operations.",
    },
    {
      title: "High Performance",
      info: "Experience unparalleled speed, reliability, and scalability with our high-performance cloud solutions, designed to elevate your business operations.",
    },
    {
      title: "24/7 Support",
      info: "Count on our dedicated team to provide round-the-clock assistance, ensuring your operations run smoothly at all times.",
    },
  ];
  return (
    <Wrapper className={clsx(classes.features)}>
      {features.map((feature, i) => (
        <div className={classes.feature} key={i}>
          <Heading info lg>
            {feature.title}
          </Heading>{" "}
          <Text textTeritary base>
            {feature.info}
          </Text>
        </div>
      ))}
    </Wrapper>
  );
};

export default Features;
