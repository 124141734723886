import React, { useState } from "react";
import classes from "./BillingSettings.module.css";
import { Button, Heading, Text } from "../../../components/common";
import Switch from "../../../components/common/Switch/Switch";
import { FiPlus } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import { masterCardLogo, paypalLogo, visaLogo } from "../../../images";
import clsx from "clsx";
const BillingSettings = () => {
  const [autoPayment, setAutoPayment] = useState(true);
  const [emailMeInvoices, setEmailMeInvoices] = useState(false);
  const paymentMethdos = [
    {
      cardLogo: paypalLogo,
      cardName: "Paypal",
      info: "ex****28@gmail.com",
      cardNumber: "****1234",
    },
    {
      cardLogo: visaLogo,
      cardName: "Visa Card",
      info: "JOHN DOE",
      cardNumber: "****1234",
    },
    {
      cardLogo: masterCardLogo,
      cardName: "MasterCard",
      info: "JOHN DOE",
      cardNumber: "****1234",
    },
  ];
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading lg>Settings</Heading>
        <Text textTeritary className={classes.info}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Text>
      </div>
      <div className={classes.paymentContainer}>
        <Text primary font600>
          Payment
        </Text>
        <div className={classes.switchContainer}>
          <Text textTeritary>Auto Payment</Text>
          <Switch
            checked={autoPayment}
            onChange={() => setAutoPayment((prev) => !prev)}
          />
        </div>
        <div className={classes.switchContainer}>
          <Text textTeritary>Email me Invoice</Text>
          <Switch
            checked={emailMeInvoices}
            onChange={() => setEmailMeInvoices((prev) => !prev)}
          />
        </div>
      </div>
      <div className={classes.paymentMethodContainer}>
        <div className={classes.paymentMethodHeader}>
          <Text primary font600>
            Payment Method
          </Text>
          <Button transparent className={classes.button}>
            Add Payment Method <FiPlus className={classes.addIcon} />
          </Button>
        </div>
        <div className={clsx(classes.methods, "overflow")}>
          {paymentMethdos.map((el, i) => (
            <React.Fragment key={i}>
              <div className={clsx(classes.item, classes.logoAndName)}>
                <img src={el.cardLogo} alt="#" className={classes.cardLogo} />
                <Text textTeritary className={classes.name}>
                  {el?.cardName}
                </Text>
              </div>
              <Text textTeritary className={classes.item}>
                {el?.info}
              </Text>
              <Text textTeritary className={classes.item}>
                {el?.cardNumber}
              </Text>
              <div className={classes.deleteContainer}>
                <RiDeleteBin6Line className={classes.deleteIcon} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BillingSettings;
