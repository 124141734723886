import React from "react";
import classes from "./AccountCreated.module.css";
import { createdImg } from "../../../images";
import { Button, Heading, Text } from "../../../components/common";
import { useNavigate } from "react-router-dom";

const AccountCreated = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.wrapper}>
      <img src={createdImg} alt="#" className={classes.img} />{" "}
      <div className={classes.header}>
        <Heading xl2 textCenter>
          Account Created!
        </Heading>
        <Text secondary textCenter>
          Your account has been created successfully
        </Text>
      </div>{" "}
      <Button
        btnSuccess
        onClick={() => {
          navigate("/sign-in");
        }}
      >
        Proceed to login!
      </Button>
    </div>
  );
};

export default AccountCreated;
