import React, { useState } from "react";
import classes from "./ContactUs.module.css";
import { Button, Heading, Input, Text } from "../../components/common";
import { FaAngleRight } from "react-icons/fa6";
import Dropdown from "../../components/common/Dropdown/Dropdown";
const countries = ["USA", "UK"];
const businessTypes = ["Type1", "Type2", "Type3"];
const ContactUs = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [showCountryDropdown, setShowCountryDropdown] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [showBusinessTypeDropdown, setShowBusinessTypeDropdown] = useState("");
  const [selectedBusinessType, setSelectedBusinessType] = useState("");

  return (
    <div className={classes.wrapper}>
      {" "}
      <Heading xl5 className={classes.heading}>
        Drop a message for us,{" "}
        <span className="highlight">we’ll get back to you</span>{" "}
      </Heading>{" "}
      <div className={classes.container}>
        <div className={classes.inputWrapper}>
          <Input
            type="text"
            value={fullName}
            setValue={setFullName}
            placeholder="Full mame"
          />{" "}
          <Input
            type="email"
            value={email}
            setValue={setEmail}
            placeholder="Email"
          />
          <div className={classes.inputContainer}>
            {" "}
            <Dropdown
              items={countries}
              isActive={showCountryDropdown}
              setIsActive={setShowCountryDropdown}
              selectedValue={selectedCountry}
              onSelect={(val) => setSelectedCountry(val)}
              placeholder="Country"
            />{" "}
            <Input
              type="tel"
              value={phoneNumber}
              setValue={setPhoneNumber}
              placeholder="Phone Number"
            />
          </div>
          <div className={classes.inputContainer}>
            <Input
              type="text"
              value={companyName}
              setValue={setCompanyName}
              placeholder="Company"
            />
            <Dropdown
              items={businessTypes}
              isActive={showBusinessTypeDropdown}
              setIsActive={setShowBusinessTypeDropdown}
              selectedValue={selectedBusinessType}
              onSelect={(val) => setSelectedBusinessType(val)}
              placeholder="Business Type"
            />
          </div>
          <textarea
            className={classes.message}
            name="message"
            id="message"
            rows={3}
            cols={3}
            placeholder="Detail message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <Button btnPrimary wFull onClick={() => {}}>
            Submit
          </Button>
        </div>
        <div className={classes.infoContainer}>
          <Heading lg semiBold>
            Contact with our support team
          </Heading>
          <Text base textTeritary>
            You need to create an account to contact with our support team.
          </Text>{" "}
          <Button btnInfo onClick={() => {}} className={classes.button}>
            Create an Account <FaAngleRight />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
