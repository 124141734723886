import React, { useRef, useState } from "react";
import { Button, Input, Text } from "../../../../components/common";
import classes from "./General.module.css";

import { BiEditAlt } from "react-icons/bi";

import Dropdown from "../../../../components/common/Dropdown/Dropdown";
const currencies = ["USD", "EUR"];
const General = () => {
  const [firstName, setFirstName] = useState("Imam");
  const [lastName, setLastName] = useState("Hossain");
  const [email, setEmail] = useState("example@domain.com");
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [phoneNumber, setPhoneNumber] = useState("01XXXXXXXXXX");
  const [companyName, setCompanyName] = useState("Example");
  const [isEmailEditable, setIsEmailEditable] = useState(false);

  const emailRef = useRef(null);

  const toggleEmailEditable = () => {
    setIsEmailEditable((prev) => !prev);
    if (!isEmailEditable && emailRef.current) {
      emailRef.current.focus();
    }
  };
  return (
    <div className={classes.allInputs}>
      <div className={classes.inputWrapper}>
        <Text textTeritary>First Name</Text>

        <Input
          type="text"
          value={firstName}
          setValue={setFirstName}
          placeholder="First Name"
        />
      </div>{" "}
      <div className={classes.inputWrapper}>
        <Text textTeritary>Last Name</Text>

        <Input
          type="text"
          value={lastName}
          setValue={setLastName}
          placeholder="Last Name"
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text textTeritary>Email</Text>
        <div className={classes.inputContainer}>
          <Input
            className={classes.input}
            type="email"
            value={email}
            setValue={setEmail}
            placeholder="Email"
            readOnly={!isEmailEditable}
            ref={emailRef}
          />
          <BiEditAlt
            className={classes.editIcon}
            onClick={toggleEmailEditable}
          />
        </div>
      </div>{" "}
      <div className={classes.dropDownContainer}>
        <Text textTeritary>Currency</Text>
        <Dropdown
          items={currencies}
          isActive={showCurrencyDropdown}
          setIsActive={setShowCurrencyDropdown}
          selectedValue={selectedCurrency}
          onSelect={(val) => setSelectedCurrency(val)}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text textTeritary>Phone Number</Text>

        <Input
          type="tel"
          value={phoneNumber}
          setValue={setPhoneNumber}
          placeholder="Phone Number"
        />
      </div>{" "}
      <div className={classes.inputWrapper}>
        <Text textTeritary>Company</Text>

        <Input
          type="text"
          value={companyName}
          setValue={setCompanyName}
          placeholder="Company Name"
        />
      </div>{" "}
      <div className={classes.inputWrapper}>
        <Text textTeritary></Text>

        <div className={classes.buttonContainer}>
          <Button>Save Changes</Button>
        </div>
      </div>
    </div>
  );
};

export default General;
