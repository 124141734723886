import React from "react";

import { FaAngleRight } from "react-icons/fa";

import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

import { logo } from "../../../images";
import { Button, Heading, Text } from "../../../components/common";
import clsx from "clsx";

const Footer = () => {
  return (
    <div className={clsx(classes.footer, "container")}>
      <footer className={classes.wrapper}>
        <div className={classes.itemContainer}>
          <Heading primary lg className={classes.heading}>
            Brand Name
          </Heading>

          <Link to="/features" className={classes.item}>
            Features
          </Link>
          <Link to="/pricing" className={classes.item}>
            Pricing
          </Link>
          <Link to="/blog-list" className={classes.item}>
            Blog List
          </Link>
          <Link to="/changelog" className={classes.item}>
            Changelog
          </Link>
        </div>{" "}
        <div className={classes.itemContainer}>
          <Heading primary lg className={classes.heading}>
            Company
          </Heading>

          <Link to="/about" className={classes.item}>
            About
          </Link>
          <Link to="/contact-us" className={classes.item}>
            Contact Us
          </Link>
          <Link to="/terms-of-use" className={classes.item}>
            Terms of Use
          </Link>
          <Link to="/privacy-policy" className={classes.item}>
            Privacy Policy
          </Link>
          <Link to="/cookie-policy" className={classes.item}>
            Cookie Policy
          </Link>
        </div>{" "}
        <div className={classes.itemContainer}>
          <Heading primary lg className={classes.heading}>
            Developers
          </Heading>

          <Link to="/features" className={classes.item}>
            Documentation
          </Link>
          <Link to="/pricing" className={classes.item}>
            Academy
          </Link>
          <Link to="/blog-list" className={classes.item}>
            Support
          </Link>
          <Button to="/sign-up" infoTransparent className={classes.button}>
            Get Started <FaAngleRight />
          </Button>
        </div>{" "}
        <div className={classes.itemContainer}>
          <Heading primary lg className={classes.heading}>
            Community
          </Heading>
          <a
            href="#/"
            target="_blank"
            rel="noreferrer"
            className={classes.item}
          >
            Github
          </a>
          <a
            href="#/"
            target="_blank"
            rel="noreferrer"
            className={classes.item}
          >
            Discord
          </a>{" "}
          <a
            href="#/"
            target="_blank"
            rel="noreferrer"
            className={classes.item}
          >
            YouTube
          </a>
        </div>
      </footer>
      <div className={classes.copyRightContainer}>
        <Text textTeritary>© 2024 Brandname. All rights reserved.</Text>
        <img src={logo} alt="#" className={classes.logo} />
      </div>
    </div>
  );
};

export default Footer;
