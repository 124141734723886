import { useEffect, useRef } from "react";
import clsx from "clsx";

import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import classes from "./Dropdown.module.css";

import useOnClickOutside from "../../../hooks";
import { Text } from "../../common";

const Dropdown = ({
  isActive,
  items = ["ABC", "ABC", "ABC", "ABC", "ABC"],
  selectedValue,
  onSelect,
  setIsActive,
  children,
}) => {
  return (
    <div className={clsx(classes.dropdown, classes.noTheme)}>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={clsx(classes.list, "overflow")}>{children}</div>
      </div>
    </div>
  );
};

export default Dropdown;
