import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import classes from "./MainLayout.module.css";
import Sidebar from "./Sidebar/Sidebar";

import { FiSidebar } from "react-icons/fi";

const MainLayout = () => {
  const [sidebar, setSidebar] = useState(false);
  return (
    <div className={classes.wrapper}>
      <div className={classes.iconContainer}>
        <FiSidebar
          className={classes.openSidebar}
          onClick={() => setSidebar(true)}
        />
      </div>
      <Sidebar sidebar={sidebar} setSidebar={setSidebar} />

      <Outlet />
    </div>
  );
};

export default MainLayout;
