import React, { useState } from "react";
import Header from "../../../Layout/AccountSettings/Header/Header";

import VerificationCode from "./VerificationCode/VerificationCode";
import CurrentPassword from "./CurrentPassword/CurrentPassword";
import SavePassword from "./SavePassword/SavePassword";
import PasswordUpdated from "./PasswordUpdated/PasswordUpdated";

const ChangePassword = ({ setShowModal, setSidebar }) => {
  const [selectedComponent, setSelectedComponent] =
    useState("Current Password");

  return (
    <>
      <Header
        heading="Change Password"
        onClose={() => setShowModal(false)}
        setSidebar={setSidebar}
      />
      {selectedComponent === "Current Password" && (
        <CurrentPassword setSelectedComponent={setSelectedComponent} />
      )}{" "}
      {selectedComponent === "Verification Code" && (
        <VerificationCode setSelectedComponent={setSelectedComponent} />
      )}{" "}
      {selectedComponent === "Save Password" && (
        <SavePassword setSelectedComponent={setSelectedComponent} />
      )}
      {selectedComponent === "Password Updated" && (
        <PasswordUpdated setSelectedComponent={setSelectedComponent} />
      )}
    </>
  );
};

export default ChangePassword;
