import React, { useState } from "react";
import classes from "./Monitoring.module.css";

import Overview from "../../../components/ServerDetails/Monitoring/Overview/Overview";
import DetailsInChart from "../../../components/ServerDetails/DetailsInChart/DetailsInChart";
import clsx from "clsx";
const Monitoring = () => {
  const [selectedTab, setSelectedTab] = useState("Overview");
  const tabs = ["Overview", "Details in Chart"];
  return (
    <div className={classes.wrapper}>
      <div className={classes.tabs}>
        {tabs.map((tab, i) => (
          <button
            className={clsx(
              classes.tab,
              selectedTab === tab && classes.selectedTab
            )}
            key={i}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      {selectedTab === "Details in Chart" && <DetailsInChart />}
      {selectedTab === "Overview" && <Overview />}
    </div>
  );
};

export default Monitoring;
