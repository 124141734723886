import React, { useState } from "react";
import classes from "./ApiKey.module.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { notFound } from "../../../images";
import { Heading, Text, Button } from "../../../components/common";
import Header from "../../../Layout/AccountSettings/Header/Header";
import { LuCopy } from "react-icons/lu";
import { copyToClipboard } from "../../../utils/utils";

const ApiKey = ({ setSidebar, setShowModal }) => {
  const [allApiKeys, setAllApiKeys] = useState([
    "d9173b0a-b097-4d52-969e-ecd8c51586f4",
    "211ad4f2-156b-4a16-a5ae-a55faf723e9d",
    "4e84ceb2-ad21-467a-a800-efb72791f4bd",
  ]);

  const handleDelete = (keyToDelete) => {
    setAllApiKeys(allApiKeys.filter((key) => key !== keyToDelete));
  };

  return (
    <>
      <Header
        heading="API Key"
        onClose={() => setShowModal(false)}
        setSidebar={setSidebar}
      />
      {allApiKeys.length === 0 ? (
        <div className={classes.wrapper}>
          <img src={notFound} alt="#" className={classes.img} />{" "}
          <div className={classes.header}>
            <Heading xl2 textCenter>
              No API Key Found
            </Heading>
            <Text secondary textCenter>
              You have no API key yet. Please create a new API key.
            </Text>
          </div>{" "}
          <Button btnSuccess onClick={() => {}}>
            Create API Key
          </Button>
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Heading base>Your API Key</Heading>

          <div className={classes.allApiKey}>
            {allApiKeys.map((key, i) => (
              <div className={classes.apiKey} key={i}>
                <Text className={classes.key}>{key}</Text>
                <div className={classes.buttonContainer}>
                  <LuCopy
                    className={classes.copyIcon}
                    onClick={() => copyToClipboard(key)}
                  />
                  <RiDeleteBin6Line
                    className={classes.deleteIcon}
                    onClick={() => handleDelete(key)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ApiKey;
