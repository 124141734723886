import React from "react";
import classes from "./LandingLayout.module.css";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";

const LandingLayout = () => {
  return (
    <div className={classes.wrapper}>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default LandingLayout;
