import React from "react";
import classes from "./Header.module.css";
import { AiOutlineClose } from "react-icons/ai";

import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../components/common";

const Header = ({ heading, onClose, setSidebar }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.header}>
      <RxHamburgerMenu
        className={classes.hamburger}
        onClick={() => setSidebar(true)}
      />
      <Text lg base0 semiBold>
        {heading}
      </Text>
      <AiOutlineClose
        className={classes.closeIcon}
        onClick={() => {
          onClose();
          navigate("/");
        }}
      />
    </div>
  );
};

export default Header;
