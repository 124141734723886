import React, { useState } from "react";
import classes from "./SavePassword.module.css";

import { Button, Input, Text } from "../../../../components/common";

const SavePassword = ({ setSelectedComponent }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  return (
    <div className={classes.infoContainer}>
      <Text secondary textCenter>
        Your identity has been verified! Please set new password. Make ensure
        your password is enough strong.
      </Text>{" "}
      <div className={classes.inputContainer}>
        <Input
          className={classes.input}
          type="password"
          value={newPassword}
          setValue={setNewPassword}
          placeholder="New Password"
        />{" "}
        <Input
          className={classes.input}
          type="password"
          value={confirmNewPassword}
          setValue={setConfirmNewPassword}
          placeholder="Confirm New Password"
        />
        <Button
          wFull
          onClick={() => {
            setSelectedComponent("Password Updated");
          }}
        >
          Save Password
        </Button>
      </div>
    </div>
  );
};

export default SavePassword;
