import React, { useRef, useState } from "react";
import classes from "./SingleServerDetails.module.css";

import { FaPowerOff } from "react-icons/fa6";
import { MdOutlineRestartAlt } from "react-icons/md";
import { GoPlusCircle } from "react-icons/go";
import { TbTransfer } from "react-icons/tb";
import { MdMoreVert } from "react-icons/md";
import { LuCopy } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";

import { convertMBtoGB, copyToClipboard } from "../../../utils/utils";
import clsx from "clsx";

import { PowerOffCompute, PowerOnCompute } from "../../../compute_api";
import { Link, useNavigate } from "react-router-dom";
import useOnClickOutside from "../../../hooks";
import { Heading, Text } from "../../../components/common";
import Dropdown from "../../../components/Dashboard/Dropdown/Dropdown";

const SingleServerDetails = ({
  id,
  image,
  name,
  createdAt,
  region,
  specs,
  ip,
  disk,
  server,
  numberOfApplications,
  numberofProjects,
}) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowDropdown(false));
  return (
    <div className={classes.singleServerDetails}>
      <div
        className={classes.logoNameAndCreatedAt}
        onClick={() => navigate(`/server/${id}`)}
      >
        <div className={classes.logoContainer}>
          <img src={image} alt="#" className={classes.logo} />{" "}
        </div>

        <div className={classes.nameAndcreatedAt}>
          <Heading base>{name}</Heading>
          <Text xs textTeritary>
            {createdAt}
          </Text>{" "}
        </div>
      </div>

      <div className={classes.specificationContainer}>
        <div className={classes.nameAndstatus}>
          <Text primary font600>
            {server?.provider}
          </Text>
          <Text
            xs
            semiBold
            className={clsx(
              classes.box,
              server?.status.toLowerCase() === "on" && classes.on,
              server?.status.toLowerCase() === "restarting" &&
                classes.restarting,
              server?.status.toLowerCase() === "off" && classes.off
            )}
          >
            {server?.status}
          </Text>
        </div>

        <div className={classes.specification}>
          <Text xs textTeritary semiBold className={classes.box}>
            {convertMBtoGB(specs?.memory)} Disk
          </Text>
          <Text xs textTeritary semiBold className={classes.box}>
            {specs?.cpu_cores} Core
          </Text>
          <Text xs textTeritary semiBold className={classes.box}>
            {specs?.primary_storage}
          </Text>
        </div>
      </div>
      <div className={classes.ipAddressContainer}>
        <Text xs textTeritary>
          IP address:
        </Text>{" "}
        <Text semiBold primary className={classes.ip}>
          {ip}

          <LuCopy
            className={classes.copyIpIcon}
            onClick={() => copyToClipboard(ip)}
          />
        </Text>
      </div>
      <div className={classes.applicationAndProjects}>
        <div className={classes.applications}>
          <Text xs semiBold textTeritary>
            Applications
          </Text>
          <Text
            xs
            semiBold
            primaryDefault
            className={classes.numbeOfApplications}
          >
            {numberOfApplications}
          </Text>
        </div>{" "}
        <div className={classes.projects}>
          <Text xs semiBold textTeritary>
            Projects
          </Text>
          <Text xs semiBold primaryDefault className={classes.numberOfProjects}>
            {numberofProjects}
          </Text>
        </div>
      </div>
      <div className={classes.moreWrapper} ref={ref}>
        <button
          className={classes.moreContainer}
          onClick={() => {
            setShowDropdown((prev) => !prev);
          }}
        >
          <MdMoreVert className={classes.more} />
        </button>{" "}
        <Dropdown isActive={showDropdown} setIsActive={setShowDropdown}>
          <button
            onClick={async () => {
              alert("Power Off");

              let instructions = {
                pid: "pid_689829591923",
                node_id: id,
              };

              await PowerOffCompute(instructions);
            }}
            className={classes.button}
          >
            <FaPowerOff className={classes.icon} />
            <Text textTeritary semiBold className={classes.item}>
              Power Off
            </Text>
          </button>{" "}
          <button
            onClick={async () => {
              alert("Power On");

              let instructions = {
                pid: "pid_689829591923",
                node_id: id,
              };

              await PowerOnCompute(instructions);
            }}
            className={classes.button}
          >
            <FaPowerOff className={classes.icon} />
            <Text textTeritary semiBold className={classes.item}>
              Power On
            </Text>
          </button>{" "}
          <button className={classes.button}>
            <MdOutlineRestartAlt className={classes.icon} />
            <Text textTeritary semiBold className={classes.item}>
              Restart
            </Text>
          </button>{" "}
          <div className={classes.addTransferClone}>
            <button className={classes.button}>
              <GoPlusCircle className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Add Application
              </Text>
            </button>{" "}
            <button className={classes.button}>
              <TbTransfer className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Transfer Server
              </Text>
            </button>{" "}
            <button className={classes.button}>
              <LuCopy className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Clone Server
              </Text>
            </button>{" "}
            <button className={classes.button}>
              <LuCopy className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Clone Server
              </Text>
            </button>
          </div>
          <button className={classes.button}>
            <RiDeleteBin6Line className={classes.icon} />
            <Text textTeritary semiBold className={classes.item}>
              Clone Server
            </Text>
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

export default SingleServerDetails;
