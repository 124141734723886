import { Routes, Route, Navigate } from "react-router-dom";
import AuthenticationLayout from "./Layout/AuthenticationLayout/AuthenticationLayout";
import SignIn from "./pages/Authentication/SignIn/SignIn";
import Signup from "./pages/Authentication/Signup/Signup";
import AccountCreated from "./pages/Authentication/AccountCreated/AccountCreated";
import EmailSent from "./pages/Authentication/EmailSent/EmailSent";
import ForgotPassword from "./pages/Authentication/ForgotPassword/ForgotPassword";
import SetNewPassword from "./pages/Authentication/SetNewPassword/SetNewPassword";
import MainLayout from "./Layout/MainLayout/MainLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import PasswordUpdated from "./pages/Authentication/PasswordUpdated/PasswordUpdated";
import { getProjectInfo, getUser } from "./project_api";
import { useEffect, useState } from "react";
import ServersPage from "./pages/ServersPage/ServersPage";
import ServerDetails from "./pages/ServerDetails/ServerDetails";
import ServerDetailsLayout from "./Layout/ServerDetailsLayout/ServerDetailsLayout";
import Monitoring from "./pages/ServerDetails/Monitoring/Monitoring";
import MasterCredential from "./pages/ServerDetails/MasterCredential/MasterCredential";
import BillingLayout from "./Layout/BillingLayout/BillingLayout";
import Billing from "./pages/Billing/Billing";

import BillingSettings from "./pages/Billing/BillingSettings/BillingSettings";
import BillingHistory from "./pages/Billing/BillingHistory/BillingHistory";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import Home from "./pages/Home/Home";
import Applications from "./pages/Applications/Applications";
import LandingLayout from "./Layout/LandingLayout/LandingLayout";
import ContactUs from "./pages/ContactUs/ContactUs";
import Affiliate from "./pages/Affiliate/Affiliate";
import AffiliateProgram from "./pages/AffiliateProgram/AffiliateProgram";
import SupportTickets from "./pages/SupportTickets/SupportTickets";
import { getComputes } from "./compute_api";

function App() {
  const [user, setUser] = useState(null);
  const [servers, setServers] = useState([]);
  const [projects, setProjects] = useState({});

  const [current_project, setCurrentProject] = useState(null);

  async function getServers() {
    let data = await getComputes("pid_689829591923");
    setServers(data);
  }
  async function getProject() {
    let data = await getProjectInfo("pid_689829591923");
    setProjects(data);
  }

  useEffect(() => {
    getServers();
  }, [current_project]);

  async function get_user() {
    let data = await getUser();

    setCurrentProject(data.projects[0]);
    setUser(data);
  }

  useEffect(() => {
    get_user();
  }, []);
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/sign-in" />} /> */}

      <Route path="" element={<LandingLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />{" "}
        <Route path="/affiliate" element={<Affiliate />} />
      </Route>
      <Route path="" element={<AuthenticationLayout />}>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/account-created" element={<AccountCreated />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/set-new-password" element={<SetNewPassword />} />
        <Route path="/password-updated" element={<PasswordUpdated />} />
        <Route path="/email-sent" element={<EmailSent />} />
      </Route>
      <Route path="" element={<MainLayout />}>
        <Route path="/dashboard" element={<Dashboard user={user} servers={servers} projects={projects} />} />
        <Route path="/servers" element={<ServersPage user={user} servers={servers} projects={projects} />} />{" "}
        <Route path="/server" element={<ServerDetailsLayout />}>
          <Route path=":id" element={<ServerDetails />} />
          <Route path="monitoring" element={<Monitoring />} />
          <Route path="master-credentials" element={<MasterCredential />} />
        </Route>{" "}
        <Route path="/affiliate-program" element={<AffiliateProgram />} />{" "}
        <Route path="/support-tickets" element={<SupportTickets />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/billing" element={<BillingLayout />}>
          {/* <Route path=":id" element={<ServerDetails />} /> */}
          <Route path="overview" element={<Billing />} />
          <Route path="history" element={<BillingHistory />} />{" "}
          <Route path="settings" element={<BillingSettings />} />
        </Route>{" "}
        <Route
          path="/accounts-settings"
          element={<Navigate to="/accounts-settings/accounts" />}
        />
        <Route path="/accounts-settings/*" element={<AccountSettings />} />
      </Route>
    </Routes>
  );
}

export default App;
