import React from "react";
import classes from "./BillingLayout.module.css";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";

const BillingLayout = () => {
  return (
    <section className={classes.wrapper}>
      <Header />
      <div className={classes.container}>
        <Sidebar />
        <Outlet />
      </div>
    </section>
  );
};

export default BillingLayout;
