import React, { useState } from "react";
import classes from "./Billing.module.css";
import { Button, Counter, Heading, Text } from "../../components/common";
import { FiPlus } from "react-icons/fi";
import clsx from "clsx";
import { Link } from "react-router-dom";
import AddFundModal from "../../Modals/AddFund/AddFund";
const Billing = () => {
  const [showAddFundModal, setShowAddFundModal] = useState(false);
  const due = 0.0;
  const billingSummary = [
    {
      serverName: "Server Name Here",
      cpu: "2vcpu",
      ram: "4gb",
      companyName: "Intel",
      time: "80 Hours",
      amount: "$8.00",
    },
    {
      serverName: "Server Name Here",
      cpu: "2vcpu",
      ram: "4gb",
      companyName: "Intel",
      time: "80 Hours",
      amount: "$8.00",
    },
    {
      serverName: "Server Name Here",
      cpu: "2vcpu",
      ram: "4gb",
      companyName: "Intel",
      time: "80 Hours",
      amount: "$8.00",
    },
    {
      serverName: "Server Name Here",
      cpu: "2vcpu",
      ram: "4gb",
      companyName: "Intel",
      time: "80 Hours",
      amount: "$8.00",
    },
    {
      serverName: "Server Name Here",
      cpu: "2vcpu",
      ram: "4gb",
      companyName: "Intel",
      time: "80 Hours",
      amount: "$8.00",
    },
  ];
  const totalAmount = billingSummary.reduce((acc, item) => {
    const amount = parseFloat(item.amount.replace("$", ""));
    return acc + amount;
  }, 0);
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.balanceContainer}>
          <div className={classes.box}>
            <div className={classes.heading}>
              <Text textTeritary textCenter>
                Estimated Due
              </Text>
              <Heading xl3 textCenter>
                $<Counter start={0} end={due} durationTime={20} />
              </Heading>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                type="button"
                disabled={due <= 0}
                btnPrimary
                wFull
                onClick={() => {}}
              >
                Make Payment
              </Button>
            </div>
          </div>{" "}
          <div className={classes.dueAndUsage}>
            <div className={classes.box}>
              <div className={classes.heading}>
                <Text textTeritary>Payment due</Text>
                <Heading lg>1 Jul 2024</Heading>
              </div>
            </div>{" "}
            <div className={classes.box}>
              <div className={classes.heading}>
                <Text textTeritary>Total Usage</Text>
                <Heading lg>
                  $<Counter start={0} end={80.0} durationTime={20} />
                </Heading>
              </div>
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.heading}>
              <Text textTeritary>Account Balance</Text>
              <Heading lg>
                $<Counter start={0} end={550.0} durationTime={20} />
              </Heading>
            </div>
            <Text textTeritary>Last top up: 12 Feb 2024</Text>
            <div className={classes.buttonContainer}>
              <Button
                type="button"
                transparent
                wFull
                onClick={() => {
                  setShowAddFundModal(true);
                }}
                className={classes.button}
              >
                Add Funds <FiPlus />
              </Button>
            </div>
          </div>
        </div>

        <div className={classes.billSummaryContainer}>
          <div className={classes.billSummaryHeader}>
            <Heading lg semiBold className={classes.billSummaryHeading}>
              <span> Billing Summary:</span> <span>Feb 1 - Mar 1, 2024</span>
            </Heading>
            <Text textTeritary>
              These charges are factored into your account balance.
            </Text>
          </div>
          <div className={clsx(classes.billSummary, "overflow")}>
            {billingSummary?.map((el, i) => (
              <React.Fragment key={i}>
                <Text
                  secondary
                  className={clsx(classes.item, classes.serverName)}
                >
                  {el.serverName}
                </Text>{" "}
                <Text secondary className={classes.item}>
                  {el.cpu} | {el.ram} | {el.companyName}
                </Text>{" "}
                <Text secondary className={classes.item}>
                  {el.time}
                </Text>{" "}
                <Text secondary className={clsx(classes.item, classes.amount)}>
                  {el.amount}
                </Text>
              </React.Fragment>
            ))}
            <Text secondary className={clsx(classes.item)}></Text>{" "}
            <Text secondary className={classes.item}></Text>{" "}
            <Text primary font600 className={classes.item}>
              Total
            </Text>{" "}
            <Text
              primary
              font600
              className={clsx(classes.item, classes.amount)}
            >
              ${totalAmount}
            </Text>
          </div>
        </div>

        <Link to="/billing-history" className={classes.link}>
          View Billing History
        </Link>
      </div>
      <AddFundModal
        isActive={showAddFundModal}
        onClose={() => setShowAddFundModal(false)}
      />
    </>
  );
};

export default Billing;
