import React from "react";
import classes from "./PasswordUpdated.module.css";
import { updatedImg } from "../../../images";
import { Button, Heading, Text } from "../../../components/common";
import { useNavigate } from "react-router-dom";

const PasswordUpdated = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.wrapper}>
      <img src={updatedImg} alt="#" className={classes.img} />{" "}
      <div className={classes.header}>
        <Heading xl2 textCenter>
          Password Updated!
        </Heading>
        <Text secondary textCenter>
          Your password has been updated. Now you can login your account with
          new password.
        </Text>
      </div>{" "}
      <Button
        btnSuccess
        onClick={() => {
          navigate("/sign-in");
        }}
      >
        Back to Login
      </Button>
    </div>
  );
};

export default PasswordUpdated;
