import React from "react";
import { Button, Text } from "../../../components/common";
import classes from "./DeleteAccount.module.css";
import Header from "../../../Layout/AccountSettings/Header/Header";
import { useNavigate } from "react-router-dom";
const DeleteAccount = ({ setShowModal, setSidebar }) => {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <Header
        heading="Delete Account"
        onClose={() => setShowModal(false)}
        setSidebar={setSidebar}
      />
      <div className={classes.wrapper}>
        <div className={classes.infoContainer}>
          <Text textTeritary>Note:</Text>
          <Text textTeritary>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore aliqua.
          </Text>
        </div>{" "}
        <Button
          className={classes.button}
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Delete Account
        </Button>
      </div>
    </>
  );
};

export default DeleteAccount;
