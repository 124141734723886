import React, { useEffect, useState } from "react";
import classes from "./VerificationCode.module.css";
import OTPInput from "otp-input-react";
import clsx from "clsx";
import Text from "../../../../components/common/Text/Text";
import Button from "../../../../components/common/Button/Button";

const VerificationCode = ({ setSelectedComponent }) => {
  const [OTP, setOTP] = useState("");
  const [otpInvalid, setOtpInvalid] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendTimeout, setResendTimeout] = useState(30);

  useEffect(() => {
    if (OTP !== "" && OTP.length === 6 && OTP !== "123456") setOtpInvalid(true);
    else setOtpInvalid(false);
  }, [OTP]);

  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setInterval(() => {
        setResendTimeout((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 45;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendDisabled]);

  const resendOTP = () => {
    // Logic to resend OTP
    setResendDisabled(true);
    // Mocking resend OTP action
    console.log("OTP Resent");
  };

  return (
    <div className={classes.wrapper}>
      {" "}
      <Text secondary textCenter>
        We sent a 6 digit verification code to your email{" "}
        <span className={classes.bold}>im****49@gmail.com.</span>
      </Text>
      <div className={classes.inutContainer}>
        {" "}
        <OTPInput
          inputClassName={clsx(classes.input, otpInvalid && classes.hasError)}
          value={OTP}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
          disabled={false}
          placeholder={["0", "0", "0", "0", "0", "0"]}
        />{" "}
        <Button wFull onClick={() => setSelectedComponent("Save Password")}>
          Verify
        </Button>
      </div>
      {otpInvalid && (
        <Text textCenter className={classes.helperError}>
          Invalid code! Please enter a valid verification code.
        </Text>
      )}
      <div className={classes.resendContainer}>
        <button
          className={classes.resendButton}
          onClick={resendOTP}
          disabled={resendDisabled}
        >
          Resend Code {resendDisabled && `(${resendTimeout}s)`}
        </button>
      </div>
    </div>
  );
};

export default VerificationCode;
