import React from "react";
import classes from "./Header.module.css";
import { Heading, Text } from "../../../components/common";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl2>Billing</Heading>
        <Text textTeritary>Las billing: 12 Feb 2024</Text>
      </div>
      <Link to="billings" className={classes.link}>
        How billing works?
      </Link>
    </div>
  );
};

export default Header;
