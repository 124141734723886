import React from "react";
import classes from "./FocusOnBusiness.module.css";

import SectionHeading from "../../common/SectionHeading/SectionHeading";
import { Button, Heading, Text } from "../../common";
import { FaAngleRight } from "react-icons/fa";

import Wrapper from "../../common/Wrapper/Wrapper";
import {
  aws,
  digitalOcean,
  googleCloud,
  laravel,
  magento,
  vultr,
  wooCommerce,
  wordpress,
} from "../../../images";

const FocusOnBusiness = () => {
  const servers = [
    { logo: digitalOcean, name: "DigitalOcean", to: "#" },
    { logo: googleCloud, name: "GoogleCloud", to: "#" },
    { logo: aws, name: "AWS Cloud", to: "#" },
    { logo: vultr, name: "Vultr Cloud", to: "#" },
  ];
  const applications = [
    { logo: wordpress, name: "WordPress", to: "#" },
    { logo: wooCommerce, name: "WooCommerce", to: "#" },
    { logo: laravel, name: "Laravel", to: "#" },
    { logo: magento, name: "Magento", to: "#" },
  ];
  return (
    <Wrapper className={classes.container}>
      <div className={classes.infoContainer}>
        <SectionHeading>Get started for free</SectionHeading>
        <Heading xl5 className={classes.heading}>
          Focus on your business, not your config scripts
        </Heading>
        <Text base textTeritary className={classes.info}>
          Enjoy seamless setup and management with our intuitive platform, designed to let you concentrate on what truly matters – growing your business
        </Text>

        <div className={classes.buttonContainer}>
          {/* if you pass to it will work link Link and if you pass href it will work like <a/> tag if you pass onClick it will work lik button tag */}
          <Button to="/sign-up" btnInfo>
            Get Started <FaAngleRight />
          </Button>{" "}
          <Button to="/contact-us" infoTransparent>
            Contact Us <FaAngleRight />
          </Button>
        </div>
      </div>
      <div className={classes.serverAndApplicatons}>
        <div className={classes.servers}>
          <Heading primary className={classes.title}>
            Servers
          </Heading>
          {servers.map((server, i) => (
            <a
              href={server.to}
              target="_blank"
              rel="noreferrer"
              className={classes.server}
              key={i}
            >
              <div className={classes.logoContainer}>
                <img src={server.logo} alt="#" className={classes.logo} />
              </div>
              <Heading textTeritary>{server.name}</Heading>
            </a>
          ))}
        </div>
        <div className={classes.applications}>
          <Heading primary className={classes.title}>
            Applications
          </Heading>{" "}
          {applications.map((application, i) => (
            <a
              href={application.to}
              target="_blank"
              rel="noreferrer"
              className={classes.application}
              key={i}
            >
              <div className={classes.logoContainer}>
                <img src={application.logo} alt="#" className={classes.logo} />
              </div>
              <Heading textTeritary>{application.name}</Heading>
            </a>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default FocusOnBusiness;
