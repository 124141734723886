import React from "react";
import classes from "./PowerdByCommunity.module.css";
import Wrapper from "../../common/Wrapper/Wrapper";
import { Button, Counter, Heading, Text } from "../../common";
import { FaAngleRight } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";

const PowerdByCommunity = () => {
  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.headingContainer}>
        <Heading xl2 className={classes.title}>
          Powered by our Community
        </Heading>
        <Button to="/sign-up" btnInfo>
          Start For Free <FaAngleRight />
        </Button>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.info}>
          <Heading xl6 className={classes.number} textCenter>
            <Counter start={0} end={20} durationTime={20} />k
            <FiPlus className={classes.plusIcon} />
          </Heading>
          <Text base secondary textCenter>
            Platform User
          </Text>
        </div>
        <div className={classes.info}>
          <Heading xl6 className={classes.number} textCenter>
            <Counter start={0} end={60} durationTime={20} />k
          </Heading>
          <Text base secondary textCenter>
            Global Data Centers
          </Text>
        </div>{" "}
        <div className={classes.info}>
          <Heading xl6 className={classes.number} textCenter>
            <Counter start={0} end={20} durationTime={250} />k
            <FiPlus className={classes.plusIcon} />
          </Heading>
          <Text base secondary textCenter>
            Website Hosted
          </Text>
        </div>
      </div>
    </Wrapper>
  );
};

export default PowerdByCommunity;
