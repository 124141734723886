import React from "react";
import classes from "./HowItWorks.module.css";
import Wrapper from "../../common/Wrapper/Wrapper";
import { Heading, Text } from "../../common";
import { checkmark } from "../../../images";

const HowItWorks = () => {
  const data = [
    {
      "title": "Select Cloud Environment",
      "info": "Choose your preferred cloud environment to deploy your server and applications seamlessly.",
      "steps": [
        "Select your cloud environment.",
        "Configure cloud settings.",
        "Prepare for deployment."
      ]
    },
    {
      "title": "Select Server Configuration",
      "info": "Pick the server configuration that best meets your needs, set up necessary parameters, and get ready to deploy.",
      "steps": [
        "Choose your server configuration.",
        "Set up essential server parameters.",
        "Finalize and prepare for activation."
      ]
    },
    {
      "title": "Host Your Applications",
      "info": "Deploy your applications effortlessly on the configured server, manage settings, and launch them smoothly.",
      "steps": [
        "Deploy your applications.",
        "Manage application settings.",
        "Launch and monitor applications."
      ]
    }
  ];
  return (
    <Wrapper className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl6>
          How it <span className={"highlight"}>works</span>
        </Heading>
        <Text xl secondary className={classes.tagline}>
          Effortlessly set up and deploy a quick starter in just minutes with
          detail instructions on its functionality.
        </Text>
      </div>
      <div className={classes.container}>
        {data.map((el, i) => (
          <div className={classes.stepsContainer} key={i}>
            <Heading xl5 className={classes.id}>
              {" "}
              {i + 1 < 9 ? `0${i + 1}` : i + 1}{" "}
            </Heading>
            <Heading xl>{el.title}</Heading>
            <Text base textTeritary>
              {el.info}
            </Text>
            <div className={classes.steps}>
              {el.steps.map((step, id) => (
                <Text base textTeritary className={classes.step} key={id}>
                  <img
                    src={checkmark}
                    alt="#"
                    className={classes.checkmarkIcon}
                  />{" "}
                  {step}
                </Text>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default HowItWorks;
