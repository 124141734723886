import React from "react";
import classes from "./PasswordUpdated.module.css";

import {  Heading, Text } from "../../../../components/common";

import { updatedImg } from "../../../../images";

const PasswordUpdated = () => {

  return (
    <div className={classes.wrapper}>
      <img src={updatedImg} alt="#" className={classes.img} />{" "}
      <div className={classes.header}>
        <Heading xl2 textCenter>
          Password Updated!
        </Heading>
        <Text secondary textCenter>
          Your password has been updated. Now you can login your account with
          new password.
        </Text>
      </div>{" "}
    </div>
  );
};

export default PasswordUpdated;
