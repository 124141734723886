import React, { useRef, useState } from "react";
import classes from "./MasterCredential.module.css";
import { LuCopy } from "react-icons/lu";
import { BiEditAlt } from "react-icons/bi";
import { Heading, Input, Text } from "../../../components/common";
import { copyToClipboard } from "../../../utils/utils";

const MasterCredential = () => {
  const [isUserNameEditable, setIsUserNameEditable] = useState(false);
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);
  const [userName, setUserName] = useState("master_lkjh124");
  const [password, setPassword] = useState("123456");
  const userNameRef = useRef(null);
  const passwordRef = useRef(null);

  const toggleUserNameEditable = () => {
    setIsUserNameEditable((prev) => !prev);
    if (!isUserNameEditable && userNameRef.current) {
      userNameRef.current.focus();
    }
  };

  const togglePasswordEditable = () => {
    setIsPasswordEditable((prev) => !prev);
    if (!isPasswordEditable && passwordRef.current) {
      passwordRef.current.focus();
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading lg>Master Credentials</Heading>
        <Text textTeritary className={classes.info}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Text>
      </div>
      <div className={classes.details}>
        <Heading textTeritary className={classes.heading}>
          Details
        </Heading>
        <div className={classes.items}>
          <Text textTeritary>Public IP</Text>
          <div className={classes.copyContainer}>
            <Text primary>58.124.84.255 </Text>{" "}
            <LuCopy
              className={classes.copyIcon}
              onClick={() => copyToClipboard("58.124.84.255")}
            />
          </div>
        </div>
        <div className={classes.items}>
          {" "}
          <Text textTeritary>Username</Text>
          <div className={classes.inputContainer}>
            <Input
              className={classes.input}
              type="text"
              value={userName}
              setValue={setUserName}
              placeholder="Username"
              readOnly={!isUserNameEditable}
              ref={userNameRef}
            />
            <BiEditAlt
              className={classes.editIcon}
              onClick={toggleUserNameEditable}
            />
          </div>
        </div>
        <div className={classes.items}>
          <Text textTeritary>Password</Text>
          <div className={classes.inputContainer}>
            <Input
              className={classes.input}
              type="password"
              value={password}
              setValue={setPassword}
              placeholder="Password"
              readOnly={!isPasswordEditable}
              ref={passwordRef}
            />
            <BiEditAlt
              className={classes.editIcon}
              onClick={togglePasswordEditable}
            />
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default MasterCredential;
