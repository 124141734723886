import React, { useState, useEffect, useRef } from "react";
import classes from "./SupportTeam.module.css";
import { Button, Heading, Text } from "../../../../components/common";
import clsx from "clsx";

const SupportTeam = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const dummyMessages = [
      {
        text: "Hello! I'm sorry to hear that you're having issues with your smartphone. Let's see if we can troubleshoot the problem together. Have you tried restarting your device to see if that resolves the freezing issue?",
        sender: "Support Team",
      },
      {
        text: "Yes, I've tried restarting it a few times, but the problem persists.",
        sender: "You",
      },
      {
        text: "Alright, let's try a few other troubleshooting steps. Have you recently installed any new apps or updates that might be causing conflicts?",
        sender: "Support Team",
      },
    ];
    setMessages(dummyMessages);
    scrollToBottom();
  }, []);

  const sendMessage = () => {
    if (input.trim() !== "") {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: input, sender: "You" },
      ]);
      setInput("");
      setTimeout(scrollToBottom, 0); // Ensure it happens after the DOM updates
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={clsx(classes.container)}>
      <div className={clsx(classes.messages, "overflow")}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={clsx(
              classes.message,
              msg.sender === "You"
                ? classes.userMessage
                : classes.supportMessage
            )}
          >
            {msg.sender !== "You" && (
              <Heading sm secondary>
                {msg.sender}:{" "}
              </Heading>
            )}
            <Text secondary>{msg.text}</Text>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <div className={classes.inputContainer}>
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && !e.shiftKey && sendMessage()}
          className={classes.input}
          placeholder="Write your text..."
          rows="1"
        />
        <Button btnPrimary onClick={sendMessage} className={classes.button}>
          Send
        </Button>
      </div>
    </div>
  );
};

export default SupportTeam;
