import React, { useEffect, useState } from "react";
import Servers from "./Servers/Servers";
import classes from "./Dashboard.module.css";
import Tabs from "../../components/Dashboard/Tabs/Tabs";
import { Heading, Text } from "../../components/common";
import Info from "./Info/Info";
import { getComputes } from "../../compute_api";
import { getProjectInfo } from "../../project_api";
const Dashboard = ({ user, projects, servers }) => {
  const tabs = [
    "Servers",
    //  "Applications", "Projects"
  ];
  const [selectedTab, setSelectedTab] = useState("Servers");

  return (
    <>
      <div className={classes.header}>
        <Heading xl2>Good afternoon, {user?.first_name}</Heading>
        <Text textTeritary>Welcome to {process.env.REACT_APP_NAME}</Text>
      </div>
      <div className={classes.container}>
        <Info servers={servers} projects={projects} user={user} />
        <div className={classes.wrapper}>
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          {selectedTab === "Servers" && <Servers compute_nodes={servers} />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
