import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import { FaChevronDown } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import {
  billingHistoryIcon,
  billingHistoryIconActive,
  billingIcon,
  billingIconActive,
  settingsIcon,
  settingsIconActive,
} from "../../../images";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Heading } from "../../../components/common";
const Sidebar = ({ id }) => {
  const [sidebar, setSidebar] = useState(false);
  const [activeLink, setActiveLink] = useState("Server Details");
  const navItems = [
    {
      icons: [billingIcon, billingIconActive],
      navItem: "Overview",
      to: `/billing/overview`,
    },
    {
      icons: [billingHistoryIcon, billingHistoryIconActive],
      navItem: "Billing History",
      to: "/billing/history",
    },
    {
      icons: [settingsIcon, settingsIconActive],
      navItem: "Settings",
      to: "/billing/settings",
    },
  ];

  const handleClick = (navItem) => {
    setActiveLink(navItem);
    setSidebar(false);
  };
  return (
    <div className={classes.wrapper}>
      <div
        className={classes.header}
        onClick={() => setSidebar((prev) => !prev)}
      >
        <Heading base semiBold>
          {activeLink}
        </Heading>
        <FaChevronDown className={classes.icon} />
      </div>
      <div className={clsx(classes.navContainer, sidebar && classes.show)}>
        <div className={classes.navItems}>
          {navItems.map((el, i) => (
            <NavLink
              to={el.to}
              key={i}
              className={({ isActive }) =>
                isActive
                  ? [classes.navItem, classes.navActive].join(" ")
                  : classes.navItem
              }
              onClick={() => handleClick(el.navItem)}
            >
              {({ isActive, isPending }) => {
                return (
                  <>
                    <img
                      src={isActive ? el.icons[1] : el.icons[0]}
                      alt="#"
                      className={classes.navIcon}
                    />
                    <span>{el.navItem}</span>
                  </>
                );
              }}
            </NavLink>
          ))}
        </div>
        <IoClose
          className={clsx(classes.close, classes.icon)}
          onClick={() => setSidebar(false)}
        />
      </div>
    </div>
  );
};

export default Sidebar;
