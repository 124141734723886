import React, { useState } from "react";
import classes from "./ForgotPassword.module.css";
import { logo } from "../../../images";
import { Button, Heading, Input, Text } from "../../../components/common";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  return (
    <div className={classes.wrapper}>
      <img src={logo} alt="#" className={classes.img} />{" "}
      <div className={classes.header}>
        <Heading xl2 textCenter>
          Forgot Password?
        </Heading>
        <Text secondary textCenter>
          Enter your account email. We’ll send you a password reset instruction.
        </Text>
      </div>{" "}
      <div className={classes.inputContainer}>
        {" "}
        <Input
          type="email"
          placeholder="Email"
          value={email}
          setValue={setEmail}
        />
        <Button
          wFull
          btnPrimary
          onClick={() => {
            navigate("/email-sent");
          }}
        >
          Email Me Instruction
        </Button>
      </div>{" "}
      <Text textTeritary className={classes.ask} primaryContent textCenter>
        Back to
        <Link to="/sign-in" className={classes.highlight}>
          Login
        </Link>
      </Text>
    </div>
  );
};

export default ForgotPassword;
