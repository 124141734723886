import React, { useState, useEffect } from "react";

import { IoMdClose } from "react-icons/io";
import { AiOutlineAlignRight } from "react-icons/ai";
import classes from "./Navbar.module.css";
import { Link, NavLink } from "react-router-dom";

import clsx from "clsx";
import { Button } from "../../../components/common";
import { mainLogo } from "../../../images";

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    { navItem: "Home", to: "/" },

    // { navItem: "Pricing", to: "/pricing" },
    // { navItem: "Documentation", to: "/documentation" },
    { navItem: "Affiliate", to: "/affiliate" },
    // { navItem: "Blog", to: "/blog" },
    { navItem: "Contact", to: "/contact-us" },
  ];

  return (
    <div
      className={[classes.wrapper, isScrolled && classes.wrapperBg].join(" ")}
    >
      <header className={[classes.header, "container"].join(" ")}>
        <Link
          className={[classes.logoContainer].join(" ")}
          to="/"
          onClick={() => setSidebar((prev) => !prev)}
        >
          {" "}
          <img src={mainLogo} alt="#" className={classes.logo} />
        </Link>
        <div
          className={[classes.navItems, sidebar && classes.sidebar].join(" ")}
        >
          {navItems.map((el, i) => (
            <NavLink
              key={i}
              to={el.to}
              onClick={() => setSidebar(false)}
              className={({ isActive }) =>
                isActive
                  ? clsx(classes.navItem, classes.navActive)
                  : classes.navItem
              }
            >
              {el.navItem}
            </NavLink>
          ))}

          <div className={classes.buttons}>
            <Button to="/sign-in" className={classes.login}>
              Login
            </Button>
            <Button to="/sign-up" className={classes.button} onClick={() => { }}>
              Start Free
            </Button>
          </div>
        </div>{" "}
        <div className={classes.buttonContainer}>
          {sidebar ? (
            <IoMdClose
              className={classes.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          ) : (
            <AiOutlineAlignRight
              className={clsx(classes.icon, classes.hamburger)}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}
        </div>
      </header>
    </div>
  );
};

export default Navbar;
