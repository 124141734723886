import React, { useState } from "react";
import classes from "./CurrentPassword.module.css";

import { Button, Input, Text } from "../../../../components/common";
import { Link } from "react-router-dom";

const CurrentPassword = ({ setSelectedComponent }) => {
  const [currentPassword, setCurrentPassword] = useState("");

  return (
    <div className={classes.infoContainer}>
      <Text secondary textCenter>
        Enter your current password to proceed to the next step.
      </Text>{" "}
      <div className={classes.inputContainer}>
        <Input
          className={classes.input}
          type="password"
          value={currentPassword}
          setValue={setCurrentPassword}
          placeholder="Current Password"
        />
        <Button
          wFull
          onClick={() => {
            setSelectedComponent("Verification Code");
          }}
        >
          Continue
        </Button>
      </div>
      <Link to="/forgot-password" className={classes.link}>
        Forgot Password?
      </Link>
    </div>
  );
};

export default CurrentPassword;
