import React from "react";
import classes from "./Overview.module.css";
import { Heading, Text } from "../../../common";
import Progressbar from "../../../common/Progressbar/Progressbar";
import clsx from "clsx";
const Overview = () => {
  const usageData = [
    { label: "Ram Usage", usage: "800 MB", limit: "1024 MB" },
    { label: "CPU Usage", usage: "1.2 GHz", limit: "3.2 GHz" },
    { label: "Disk Usage", usage: "16.08 GB", limit: "25 GB" },
  ];

  const bandwidth = {
    usage: "62.4 GB",
    download: "43.7 GB",
    upload: "18.7 GB",
  };

  const applicationUsageDetails = [
    {
      name: "E-Commerce Shop",
      ram: "100 MB",
      cpu: "8%",
      disk: "4.3 GB",
      bandwidth: "10 GB",
    },
    {
      name: "Electrical & Electronics Shop",
      ram: "50 MB",
      cpu: "3%",
      disk: "1.2 GB",
      bandwidth: "5 GB",
    },
    {
      name: "Saas Solution",
      ram: "65 MB",
      cpu: "6%",
      disk: "2.5 GB",
      bandwidth: "16 GB",
    },
    {
      name: "University ERP Solution",
      ram: "80 MB",
      cpu: "4%",
      disk: "1.8 GB",
      bandwidth: "6 GB",
    },
    {
      name: "Super Shop ERP",
      ram: "120 MB",
      cpu: "7%",
      disk: "3.1 GB",
      bandwidth: "15 GB",
    },
    {
      name: "Portfolio Website",
      ram: "50 MB",
      cpu: "2%",
      disk: "0.5 GB",
      bandwidth: "12 GB",
    },
  ];

  const calculatePercentage = (usage, limit) => {
    const usageValue = parseFloat(usage);
    const limitValue = parseFloat(limit);
    if (!isNaN(usageValue) && !isNaN(limitValue) && limitValue !== 0) {
      return ((usageValue / limitValue) * 100).toFixed(2);
    }
    return 0;
  };
  return (
    <>
      <div className={classes.usagesInfoContainer}>
        <div className={classes.usageContainer}>
          {usageData?.map((el, i) => (
            <div className={classes.usageBox} key={i}>
              <div className={classes.infoBox}>
                <Text textPrimary className={classes.item}>
                  {el?.label}
                </Text>
                <Heading heading lg semibold className={classes.item}>
                  {el?.usage}
                </Heading>

                <Text textTeritary xs className={classes.item}>
                  Limit: {el?.limit}
                </Text>
              </div>
              <Progressbar progress={calculatePercentage(el.usage, el.limit)} />
            </div>
          ))}
        </div>
        <div className={classes.bandwithInfo}>
          <div className={classes.usageBox}>
            <div className={classes.infoBox}>
              <Text textPrimary className={classes.item}>
                Bandwidth Usage
              </Text>
              <Heading heading lg semibold className={classes.item}>
                {bandwidth?.usage}
              </Heading>

              <Text textTeritary xs className={classes.item}>
                Limit:Unlimited
              </Text>
            </div>{" "}
          </div>
          <div className={classes.usageBox}>
            <div className={classes.infoBox}>
              <Text textPrimary className={classes.item}>
                Download
              </Text>
              <Heading heading lg semibold className={classes.item}>
                {bandwidth?.download}
              </Heading>
            </div>
            <Progressbar
              progress={calculatePercentage(
                bandwidth?.download,
                bandwidth?.usage
              )}
            />
          </div>{" "}
          <div className={classes.usageBox}>
            <div className={classes.infoBox}>
              <Text textPrimary className={classes.item}>
                Upload
              </Text>
              <Heading heading lg semibold className={classes.item}>
                {bandwidth?.upload}
              </Heading>
            </div>
            <Progressbar
              progress={calculatePercentage(
                bandwidth?.upload,
                bandwidth?.usage
              )}
            />
          </div>
        </div>
      </div>

      <div className={clsx(classes.applicationUsageDetails, "overflow")}>
        <Text
          textPlaceholer
          className={clsx(classes.tableHeading, classes.firstHeading)}
        >
          Application
        </Text>{" "}
        <Text textPlaceholer className={classes.tableHeading}>
          Ram
        </Text>
        <Text textPlaceholer className={classes.tableHeading}>
          CPU
        </Text>
        <Text textPlaceholer className={classes.tableHeading}>
          Disk
        </Text>
        <Text
          textPlaceholer
          className={clsx(classes.tableHeading, classes.lastHeading)}
        >
          Bandwidth
        </Text>
        {applicationUsageDetails.map((el, i) => (
          <React.Fragment key={i}>
            <Text
              primary
              semiBold
              className={clsx(classes.name, classes.details)}
            >
              {el.name}
            </Text>
            <Text
              textTeritary
              semiBold
              className={clsx(classes.ram, classes.details)}
            >
              {el.ram}
            </Text>
            <Text
              textTeritary
              semiBold
              className={clsx(classes.cpu, classes.details)}
            >
              {el.cpu}
            </Text>
            <Text
              textTeritary
              semiBold
              className={clsx(classes.disk, classes.details)}
            >
              {el.disk}
            </Text>
            <Text
              textTeritary
              semiBold
              className={clsx(classes.bandwidth, classes.details)}
            >
              {el.bandwidth}
            </Text>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default Overview;
