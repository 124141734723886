import React, { useState } from "react";

import classes from "./Accounts.module.css";

import clsx from "clsx";
import General from "./General/General";
import Address from "./Address/Address";
import Header from "../../../Layout/AccountSettings/Header/Header";

const Accounts = ({ setShowModal, setSidebar }) => {
  const [selectedTab, setSelectedTab] = useState("General");
  const tabs = ["General", "Address"];
  return (
    <>
      <Header
        heading="Accounts"
        onClose={() => setShowModal(false)}
        setSidebar={setSidebar}
      />
      <div className={classes.tabs}>
        {tabs.map((tab, i) => (
          <button
            className={clsx(
              classes.tab,
              selectedTab === tab && classes.selectedTab
            )}
            key={i}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>{" "}
      {selectedTab === "General" && <General />}{" "}
      {selectedTab === "Address" && <Address />}
    </>
  );
};

export default Accounts;
