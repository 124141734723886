import React from "react";
import { Outlet } from "react-router-dom";
import classes from "./AuthenticationLayout.module.css";
const AuthenticationLayout = () => {
  return (
    <div className={classes.wrapper}>
      <Outlet />
    </div>
  );
};

export default AuthenticationLayout;
