import React, { useState } from "react";
import classes from "./Signup.module.css";
import { Button, Heading, Input, Text } from "../../../components/common";
import {
  checkmark,
  githubLogo,
  googleLogo,
  linkedinLogo,
  logo,
} from "../../../images";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../../components/common/Dropdown/Dropdown";
import axios from "axios";

const items = [
  "Describe yourself",
  "Describe yourself1",
  "Describe yourself2",
  "Describe yourself3",
];

const Signup = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dropdownAcitve, setDropdownActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Describe yourself");

  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const socialLinks = [
    { logo: githubLogo, link: "#" },
    { logo: googleLogo, link: "#" },
    { logo: linkedinLogo, link: "#" },
  ];
  const youWillgetFree = [
    "Hybrid Cloud",
    "99.9% Uptime Guarantee",
    "24/7 Customer Support",
    "Daily Backups",
    "Scalable Resources",
    "Advanced Security Measures"
  ];

  async function sendOtp() {
    setLoading(true); // Start loading
    let api = process.env.REACT_APP_API + "/auth/signup/init";
    let body = {
      method: "Email",
      data: email,
    };

    try {
      let { data, status } = await axios.post(api, body);
      if (status === 200) {
        console.log(data);
        setOtpSent(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // Stop loading
    }
  }

  async function completeSignup() {
    setLoading(true); // Start loading
    let api = process.env.REACT_APP_API + "/auth/signup/complete";
    let body = {
      method: "Email",
      data: email,
      otp: otp,
      password: password,
    };

    try {
      let { data, status } = await axios.post(api, body);
      if (status === 201) {
        console.log(data);
        navigate("/account-created");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false); // Stop loading
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <img src={logo} alt="Logo" className={classes.logo} />

        <div className={classes.titleAndInfo}>
          <Heading xl2 semiBold>
            Start your journey
          </Heading>
          <Text secondary>Enter your credential to get started</Text>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.youWillGetFree}>
          <Heading lg font600>
            Get started
          </Heading>
          <div className={classes.freeItems}>
            {youWillgetFree?.map((el, i) => (
              <div className={classes.freeItem} key={i}>
                <img src={checkmark} alt="#" className={classes.checkMark} />
                <Text primary>{el}</Text>
              </div>
            ))}
          </div>
          <Text secondary className={classes.info}>
            By signing-up you are agree with our{" "}
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
              className={classes.highlight}
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
              className={classes.highlight}
            >
              Policies
            </a>{" "}
          </Text>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.allSocialLinks}>
            {socialLinks?.map((el, i) => (
              <a
                key={i}
                href={el.link}
                target="_blank"
                rel="noreferrer"
                className={classes.socialContainer}
              >
                <img src={el.logo} alt="#" className={classes.socialLogo} />
              </a>
            ))}
          </div>
          <Text sm textPlaceholer textCenter className={classes.or}>
            or
          </Text>
          {!otpSent && (
            <div className={classes.inputContainer}>
              <div className={classes.inputWrapper}>
                {/* <Input
                type="text"
                placeholder="First Name"
                value={firstName}
                setValue={setFirstName}
              />
              <Input
                type="text"
                placeholder="Last Name"
                value={lastName}
                setValue={setLastName}
              /> */}
              </div>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                setValue={setEmail}
              />
              <Input
                type="password"
                placeholder="Password"
                value={password}
                setValue={setPassword}
              />
              <Input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                setValue={setConfirmPassword}
              />
              {/* <Dropdown
              isActive={dropdownAcitve}
              setIsActive={setDropdownActive}
              items={items}
              selectedValue={selectedValue}
              onSelect={(val) => setSelectedValue(val)}
            /> */}
              <Button
                wFull
                onClick={() => {
                  sendOtp();
                }}
                loading={loading} // Pass loading state
              >
                Sign-up
              </Button>
            </div>
          )}

          {otpSent && (
            <div className={classes.inputContainer}>
              <div className={classes.inputWrapper}>
                {/* <Input
                type="text"
                placeholder="First Name"
                value={firstName}
                setValue={setFirstName}
              />
              <Input
                type="text"
                placeholder="Last Name"
                value={lastName}
                setValue={setLastName}
              /> */}
              </div>
              {/* <Input
              type="email"
              placeholder="Email"
              value={email}
              setValue={setEmail}
            /> */}
              <Input
                type="otp"
                placeholder="otp"
                value={otp}
                setValue={setOtp}
              />
              {/* <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              setValue={setConfirmPassword}
            /> */}
              {/* <Dropdown
              isActive={dropdownAcitve}
              setIsActive={setDropdownActive}
              items={items}
              selectedValue={selectedValue}
              onSelect={(val) => setSelectedValue(val)}
            /> */}
              <Button
                wFull
                onClick={() => {
                  completeSignup();
                }}
                loading={loading} // Pass loading state
              >
                Sign-up
              </Button>
            </div>
          )}
        </div>
      </div>

      <Text textTeritary className={classes.ask} primaryContent textCenter>
        Already have an account?
        <Link to="/sign-in" className={classes.signUp}>
          Login
        </Link>
      </Text>
    </div>
  );
};

export default Signup;
