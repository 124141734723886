import React from "react";
import classes from "./TicketDetails.module.css";
import { AiOutlineClose } from "react-icons/ai";

import Modal from "../../../components/common/Modal/Modal";
import { Button, Heading, Text } from "../../../components/common";
import SupportTeam from "./SupportTeam/SupportTeam";
import clsx from "clsx";

const TicketDetailsModal = ({
  isActive,
  onClose,
  tiketId = "#FHD12345678",
  submissionDate = "12 Dec 2024 15:47",
  status = "Opened",
  priority = "High",
}) => {
  return (
    <Modal isActive={isActive} onClose={onClose} className={classes.modal}>
      {" "}
      <div className={classes.header}>
        <Text base base0 semiBold>
          Ticket #12345678
        </Text>
        <AiOutlineClose
          className={classes.closeIcon}
          onClick={() => {
            onClose();
          }}
        />
      </div>
      <div className={classes.container}>
        <SupportTeam />

        <div className={classes.ticketInfoContainer}>
          <Heading base className={classes.heading}>
            Ticket Info
          </Heading>
          <div className={classes.spaceBetween}>
            <Text textTeritary>Ticket</Text> <Text textTeritary>{tiketId}</Text>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <Text textTeritary>Submited</Text>{" "}
            <Text textTeritary>{submissionDate}</Text>
          </div>
          <div className={classes.spaceBetween}>
            <Text textTeritary>Submited</Text>{" "}
            <Text
              primary
              className={clsx(
                classes.status,
                status.toLowerCase() === "pending" && classes.pending,
                status.toLowerCase() === "opened" && classes.opened,
                status.toLowerCase() === "solved" && classes.solved
              )}
            >
              {status}
            </Text>
          </div>{" "}
          <div className={classes.spaceBetween}>
            <Text textTeritary>Priority</Text>{" "}
            <Text
              textTeritary
              className={clsx(
                classes.priority,
                priority.toLowerCase() === "high" && classes.high,
                priority.toLowerCase() === "low" && classes.low,
                priority.toLowerCase() === "medium" && classes.medium
              )}
            >
              {priority}
            </Text>
          </div>
          <Text base primary className={classes.info}>
            Simple and awesome title here lorem ipsum
          </Text>
          <Button btnSuccess>Mark solved this Tticket</Button>
        </div>
      </div>
    </Modal>
  );
};

export default TicketDetailsModal;
