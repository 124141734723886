import axios from "axios";
import Cookies from "js-cookie";
import { act } from "react";
import { digitalOcean } from "./images";
export const getComputes = async (pid) => {
    let api = process.env.REACT_APP_API + "/compute/list/" + pid;
    try {
        let { data } = await axios.get(api, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        let servers = data?.map((el) => {
            let provider = el.provider;
            let status = el.status;
            let logo = digitalOcean;
            return {
                id: el.id,
                image: logo,
                ip: "0.0.0.0",
                name: el.name,
                createdAt: new Date(el.expires).toDateString(),
                ip: "0.0.0.0",
                disk: "25 GB",
                server: {
                    provider,
                    status: "On",
                },
                numberOfApplications: 2,
                numberofProjects: 10,
                specs: {
                    memory: el.specs.memory,
                    cpu_cores: el.specs.cpu_cores,
                    primary_storage: el.specs.primary_storage,
                },
                region: el.region.toUpperCase(),
            };
        });
        return servers;
    } catch (e) {

    }
}

export const PowerOffCompute = async (input) => {
    let api = process.env.REACT_APP_API + "/compute/actions";
    try {
        let { data } = await axios.post(api, {
            pid: input.pid,
            command: "Shutdown",
            node_id: input.node_id

        }, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data;
    } catch (e) {

    }
}

export const PowerOnCompute = async (input) => {
    let api = process.env.REACT_APP_API + "/compute/actions";
    try {
        let { data } = await axios.post(api, {
            pid: input.pid,
            command: "PowerOn",
            node_id: input.node_id

        }, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data;
    } catch (e) {

    }
}
export const RebootCompute = async (input) => {
    let api = process.env.REACT_APP_API + "/compute/actions";
    try {
        let { data } = await axios.post(api, {
            pid: input.pid,
            command: "Reboot",
            node_id: input.node_id

        }, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data;
    } catch (e) {

    }
}