import React, { useState } from "react";
import classes from "./SetNewPassword.module.css";
import { logo } from "../../../images";
import { Button, Heading, Input, Text } from "../../../components/common";
import { Link, useNavigate } from "react-router-dom";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  return (
    <div className={classes.wrapper}>
      <img src={logo} alt="#" className={classes.img} />{" "}
      <div className={classes.header}>
        <Heading xl2 textCenter>
          Set New Password
        </Heading>
        <Text secondary textCenter>
          Your identity has been verified! Please set new password. Make ensure
          your password is enough strong.
        </Text>
      </div>{" "}
      <div className={classes.inputContainer}>
        <Input
          type="password"
          placeholder="Password"
          value={password}
          setValue={setPassword}
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          setValue={setConfirmPassword}
        />
        <Button
          wFull
          btnPrimary
          onClick={() => {
            navigate("/password-updated");
          }}
        >
          Change Password
        </Button>
      </div>{" "}
      <Text textTeritary className={classes.ask} primaryContent textCenter>
        Back to
        <Link to="/sign-in" className={classes.highlight}>
          Login
        </Link>
      </Text>
    </div>
  );
};

export default SetNewPassword;
