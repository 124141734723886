import React from "react";

import classes from "./Sidebar.module.css";
import { Link, NavLink } from "react-router-dom";
import {
  affiliateIcon,
  affiliateIconActive,
  applicationIcon,
  applicationIconActive,
  billingIcon,
  billingIconActive,
  changeLogIcon,
  changeLogIconActive,
  dashboardIcon,
  dashboardIconActive,
  documentationIcon,
  documentationIconActive,
  logoutIcon,
  mainLogo,
  notificationIcon,
  projectIcon,
  projectsIconActive,
  serverIcon,
  serverIconActive,
  settingsIcon,
  settingsIconActive,
  supportTicketIcon,
  supportTicketIconActive,
} from "../../../images";
import clsx from "clsx";
import { nameInitial } from "../../../utils/utils";
import { Heading, Text } from "../../../components/common";

const Sidebar = ({
  sidebar,
  setSidebar,
  userImg,
  userName = "User Name",
  email = " user_email@example.com",
}) => {
  const navItems = [
    {
      icons: [dashboardIcon, dashboardIconActive],
      navItem: "Dashboard",
      to: "/dashboard",
    },
    {
      icons: [serverIcon, serverIconActive],
      navItem: "Servers",
      to: "/servers",
    },
    {
      icons: [applicationIcon, applicationIconActive],
      navItem: "Applications",
      to: "/applications",
    },
    // {
    //   icons: [projectIcon, projectsIconActive],
    //   navItem: "Projects",
    //   to: "/projects",
    // },
    {
      icons: [billingIcon, billingIconActive],
      navItem: "Billing",
      to: "/billing/overview",
    },
  ];
  const moreNavItems = [
    {
      icons: [affiliateIcon, affiliateIconActive],
      navItem: "Affiliate",
      to: "/affiliate-program",
    },
    {
      icons: [supportTicketIcon, supportTicketIconActive],
      navItem: "Support Tickets",
      to: "/support-tickets",
    },
    {
      icons: [documentationIcon, documentationIconActive],
      navItem: "Documentation",
      to: "/documentation",
    },
    {
      icons: [changeLogIcon, changeLogIconActive],
      navItem: "Changelog",
      to: "/changelog",
    },
  ];

  return (
    <>
      <section
        className={clsx(classes.sidebar, sidebar && classes.showSidebar)}
        onClick={() => setSidebar(false)}
      >
        <Link to="/" className={classes.logoContainer}>
          <img src={mainLogo} alt="#" className={classes.logo} />
        </Link>
        <div className={classes.navContainer}>
          <div className={classes.navItems}>
            {navItems.map((el, i) => (
              <NavLink
                to={el.to}
                key={i}
                className={({ isActive }) =>
                  isActive
                    ? [classes.navItem, classes.navActive].join(" ")
                    : classes.navItem
                }
                onClick={() => setSidebar((prev) => !prev)}
              >
                {({ isActive, isPending }) => {
                  return (
                    <>
                      <img
                        src={isActive ? el.icons[1] : el.icons[0]}
                        alt="#"
                        className={classes.navIcon}
                      />
                      <span>{el.navItem}</span>
                    </>
                  );
                }}
              </NavLink>
            ))}
          </div>{" "}
          <div className={classes.navItems}>
            {moreNavItems.map((el, i) => (
              <NavLink
                to={el.to}
                key={i}
                className={({ isActive }) =>
                  isActive
                    ? [classes.navItem, classes.navActive].join(" ")
                    : classes.navItem
                }
                onClick={() => setSidebar((prev) => !prev)}
              >
                {({ isActive, isPending }) => {
                  return (
                    <>
                      <img
                        src={isActive ? el.icons[1] : el.icons[0]}
                        alt="#"
                        className={classes.navIcon}
                      />
                      <span>{el.navItem}</span>
                    </>
                  );
                }}
              </NavLink>
            ))}
          </div>
          <div className={classes.bottomNavs}>
            <div className={classes.userContainer}>
              {userImg ? (
                <img src={userImg} alt="#" className={classes.userImg} />
              ) : (
                <div className={classes.avater}>
                  <Heading primaryDefault semiBold>
                    {nameInitial(userName)}
                  </Heading>
                </div>
              )}

              <div className={classes.nameContainer}>
                <Text primary semiBold>
                  {userName}
                </Text>
                <Text textTeritary xs>
                  {email.slice(0, 18)}...
                </Text>
              </div>
              <img
                src={notificationIcon}
                alt="#"
                className={classes.notificationIcon}
              />
            </div>
            <NavLink
              to="/accounts-settings/accounts"
              className={({ isActive }) =>
                isActive
                  ? [classes.navItem, classes.navActive].join(" ")
                  : classes.navItem
              }
              onClick={() => setSidebar((prev) => !prev)}
            >
              {({ isActive }) => {
                return (
                  <>
                    <img
                      src={isActive ? settingsIconActive : settingsIcon}
                      alt="#"
                      className={classes.navIcon}
                    />
                    <span>Account Settings</span>
                  </>
                );
              }}
            </NavLink>{" "}
            <NavLink to="/sign-in" className={classes.navItem}>
              <img src={logoutIcon} alt="#" className={classes.navIcon} />
              <span>Logout</span>
            </NavLink>
          </div>
        </div>
      </section>{" "}
      {sidebar && (
        <div
          className={classes.overlay}
          onClick={() => setSidebar(false)}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
