import React, { useState } from "react";
import classes from "./OtherPages.module.css";
import Wrapper from "../../common/Wrapper/Wrapper";
import { dashboardImg } from "../../../images";
import Tabs from "./Tabs/Tabs";
import { Button } from "../../common";
import { FaAngleRight } from "react-icons/fa";

const OtherPages = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [
    { name: "Dashboard", img: dashboardImg },
    { name: "Servers", img: dashboardImg },
    { name: "Applications", img: dashboardImg },
    { name: "Billing", img: dashboardImg },
    { name: "Affiliate", img: dashboardImg },
  ];
  return (
    <Wrapper className={classes.wrapper}>
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <img src={tabs[selectedTab].img} alt="#" className={classes.img} />

      <Button infoTransparent>
        View All Features <FaAngleRight />
      </Button>
    </Wrapper>
  );
};

export default OtherPages;
