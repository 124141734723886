import React, { useRef, useState } from "react";
import classes from "./SingleServer.module.css";
import { Heading, Text } from "../../common";
import { FaPowerOff } from "react-icons/fa6";
import { MdOutlineRestartAlt } from "react-icons/md";
import { GoPlusCircle } from "react-icons/go";
import { TbTransfer } from "react-icons/tb";
import { MdMoreVert } from "react-icons/md";
import { LuCopy } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";

import { copyToClipboard } from "../../../utils/utils";
import clsx from "clsx";
import Dropdown from "../Dropdown/Dropdown";
import useOnClickOutside from "../../../hooks";
import { PowerOffCompute, PowerOnCompute } from "../../../compute_api";
import { Link } from "react-router-dom";

const SingleServer = ({
  id,
  image,
  name,
  createdAt,
  region,
  specs,
  ip,
  disk,
  server,
  numberOfApplications,
  numberofProjects,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setShowDropdown(false));
  return (
    <Link to={`/server/${id}`} className={classes.singleServer}>
      <div className={classes.logoContainer}>
        <img src={image} alt="#" className={classes.logo} />{" "}
      </div>
      <div className={classes.otherInfo}>
        <div className={classes.infoContainer}>
          <Heading base>{name}</Heading>
          <div className={classes.createdAtAndIP}>
            <Text xs textTeritary>
              {region}
            </Text>{" "}
            <Text xs textTeritary className={classes.domainIp}>
              {ip}

              <LuCopy
                className={classes.copyIpIcon}
                onClick={() => copyToClipboard(ip)}
              />
            </Text>
          </div>
          <div className={classes.specification}>
            {/* <Text xs semiBold className={classes.box}>
              {server.provider}
            </Text> */}
            <Text xs semiBold className={classes.box}>
              {specs.memory} RAM
            </Text>
            <Text xs semiBold className={classes.box}>
              {specs.cpu_cores} CPU
            </Text>
            <Text xs semiBold className={classes.box}>
              {disk} Storage
            </Text>
            <Text
              xs
              semiBold
              className={clsx(
                classes.box,
                server?.status.toLowerCase() === "on" && classes.on,
                server?.status.toLowerCase() === "restarting" &&
                  classes.restarting,
                server?.status.toLowerCase() === "off" && classes.off
              )}
            >
              {server?.status}
            </Text>
          </div>
        </div>

        <div className={classes.applicationAndProjects}>
          <div className={classes.applications}>
            <Text xs semiBold textTeritary>
              Applications
            </Text>
            <Text
              xs
              semiBold
              primaryDefault
              className={classes.numbeOfApplications}
            >
              {numberOfApplications}
            </Text>
          </div>{" "}
          <div className={classes.projects}>
            <Text xs semiBold textTeritary>
              Projects
            </Text>
            <Text
              xs
              semiBold
              primaryDefault
              className={classes.numberOfProjects}
            >
              {numberofProjects}
            </Text>
          </div>
        </div>
        <div className={classes.moreWrapper} ref={ref}>
          <button
            className={classes.moreContainer}
            onClick={() => {
              setShowDropdown((prev) => !prev);
            }}
          >
            <MdMoreVert className={classes.more} />
          </button>{" "}
          <Dropdown isActive={showDropdown} setIsActive={setShowDropdown}>
            <button
              onClick={async () => {
                alert("Power Off");

                let instructions = {
                  pid: "pid_689829591923",
                  node_id: id,
                };

                await PowerOffCompute(instructions);
              }}
              className={classes.button}
            >
              <FaPowerOff className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Power Off
              </Text>
            </button>{" "}
            <button
              onClick={async () => {
                alert("Power On");

                let instructions = {
                  pid: "pid_689829591923",
                  node_id: id,
                };

                await PowerOnCompute(instructions);
              }}
              className={classes.button}
            >
              <FaPowerOff className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Power On
              </Text>
            </button>{" "}
            <button className={classes.button}>
              <MdOutlineRestartAlt className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Restart
              </Text>
            </button>{" "}
            <div className={classes.addTransferClone}>
              <button className={classes.button}>
                <GoPlusCircle className={classes.icon} />
                <Text textTeritary semiBold className={classes.item}>
                  Add Application
                </Text>
              </button>{" "}
              <button className={classes.button}>
                <TbTransfer className={classes.icon} />
                <Text textTeritary semiBold className={classes.item}>
                  Transfer Server
                </Text>
              </button>{" "}
              <button className={classes.button}>
                <LuCopy className={classes.icon} />
                <Text textTeritary semiBold className={classes.item}>
                  Clone Server
                </Text>
              </button>{" "}
              <button className={classes.button}>
                <LuCopy className={classes.icon} />
                <Text textTeritary semiBold className={classes.item}>
                  Clone Server
                </Text>
              </button>
            </div>
            <button className={classes.button}>
              <RiDeleteBin6Line className={classes.icon} />
              <Text textTeritary semiBold className={classes.item}>
                Clone Server
              </Text>
            </button>
          </Dropdown>
        </div>
      </div>
    </Link>
  );
};

export default SingleServer;
