import React from "react";

import { AiOutlineClose } from "react-icons/ai";

import classes from "./AddFund.module.css";
import Modal from "../../components/common/Modal/Modal";
import { Button, Heading, Text } from "../../components/common";
import { addFundQrCode } from "../../images";

const AddFund = ({ onClose, isActive }) => {
  return (
    <Modal isActive={isActive} onClose={onClose} className={classes.modal}>
      <div className={classes.header}>
        <Text base base0 semiBold>
          Add Funds
        </Text>
        <AiOutlineClose
          className={classes.closeIcon}
          onClick={() => {
            onClose();
          }}
        />
      </div>

      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <Heading lg semiBold className={classes.heading}>
            Scan the QR code of the device
          </Heading>
          <Text textTeritary className={classes.info}>
            Scan the QR code to add funds to your account. The minimum
            amount you can add is $10.00
          </Text>
          <Text textTeritary className={classes.info}>
            Token : USDT (BEP20)
          </Text>
          <Button transparent onClick={() => { }} className={classes.button}>
            Generate New QR
          </Button>
        </div>
        <div className={classes.qrCodeContainer}>
          <img src={addFundQrCode} alt="#" className={classes.qrCode} />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button transparent className={classes.button} onClick={onClose}>
          Close
        </Button>{" "}
        {/* if you pass href it will work like <a/ tag if you pass onClick it will work like button and if you pass to it will work like <Link> */}
        <Button onClick={() => { }}>Copy Wallet Address</Button>
      </div>
    </Modal>
  );
};

export default AddFund;
