import React from "react";
import classes from "./EmailSent.module.css";
import { emailSendImg } from "../../../images";
import { Button, Heading, Text } from "../../../components/common";

const EmailSent = () => {
  return (
    <div className={classes.wrapper}>
      <img src={emailSendImg} alt="#" className={classes.img} />{" "}
      <div className={classes.header}>
        <Heading xl2 textCenter>
          Email Sent
        </Heading>
        <Text secondary textCenter>
          Please check your email. We sent a password reset instruction to your
          email
        </Text>
        <Heading textCenter>im****49@gmail.com</Heading>
      </div>{" "}
      <Button btnPrimary onClick={() => {}}>
        Resend Instruction
      </Button>
    </div>
  );
};

export default EmailSent;
