// src/Progressbar.js
import React from "react";
import classes from "./Progressbar.module.css";

const Progressbar = ({ progress, size = 60, strokeWidth = 4 }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  const getColor = (progress) => {
    if (progress <= 25) return "#24C788"; // Red
    if (progress <= 50) return "#336FE1"; // Orange
    if (progress <= 75) return "#FFB729"; // Yellow
    return "#F24141"; // Green
  };

  const color = getColor(progress);

  return (
    <svg width={size} height={size} className={classes.progressbar}>
      <circle
        className={classes.background}
        stroke="#e6e6e6"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className={classes.foreground}
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        r={radius}
        cx={size / 2}
        cy={size / 2}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        className={classes.text}
        fill={color}
      >
        {Math.floor(progress)}%
      </text>
    </svg>
  );
};

export default Progressbar;
