import React from "react";
import classes from "./Servers.module.css";

import SingleServer from "../../../components/Dashboard/SingleServer/SingleServer";
import { aws, digitalOcean, googleCloud, vultr } from "../../../images";
import clsx from "clsx";

const Servers = ({ compute_nodes: servers }) => {


  return (
    <div className={clsx(classes.wrapper, "overflow")}>
      {servers?.map((el, i) => (
        <SingleServer {...el} key={el.project_id} />
      ))}
    </div>
  );
};

export default Servers;
