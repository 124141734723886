import React from "react";
import classes from "./ServerDetails.module.css";
import { Heading, Text } from "../../components/common";
import { LuCopy } from "react-icons/lu";
import { copyToClipboard } from "../../utils/utils";
import clsx from "clsx";
const ServerDetails = ({ status = "on" }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading lg>About Server</Heading>
        <Text textTeritary className={classes.info}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          consequat.
        </Text>
      </div>
      <div className={classes.details}>
        <Heading textTeritary className={classes.heading}>
          Details
        </Heading>
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            Server
          </Text>
          <Text primary className={classes.value}>
            DigitalOcean
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            Created on
          </Text>
          <Text primary className={classes.value}>
            24 February 2024
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            Server location
          </Text>
          <Text primary className={classes.value}>
            New York City
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            IP address
          </Text>
          <Text primary className={classes.value}>
            58.124.84.255
            <LuCopy
              className={classes.copyIcon}
              onClick={() => copyToClipboard(" 58.124.84.255")}
            />
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            Disk space
          </Text>
          <Text primary className={classes.value}>
            25 GB
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            CPU
          </Text>
          <Text primary className={classes.value}>
            1 Core
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            RAM
          </Text>
          <Text primary className={classes.value}>
            1 GB
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            Operating System
          </Text>
          <Text primary className={classes.value}>
            Ubuntu 22
          </Text>
        </div>{" "}
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            Operating System
          </Text>
          <Text primary className={classes.value}>
            Ubuntu 22
          </Text>
        </div>
        <div className={classes.spaceBetween}>
          <Text textTeritary className={classes.label}>
            Status
          </Text>
          <Text
            className={clsx(
              classes.value,
              classes.status,
              status?.toLowerCase() === "on" && classes.on,
              status?.toLowerCase() === "restarting" && classes.restarting,
              status?.toLowerCase() === "off" && classes.off
            )}
          >
            Power is {status}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ServerDetails;
