import React, { useState } from "react";
import classes from "./DetailsInChart.module.css";
import Chart from "../Chart/Chart";
import Dropdown from "../../common/Dropdown/Dropdown";
import { Text } from "../../common";
const DetailsInChart = () => {
  const [showUsageDropdown, setShowUsageDropdown] = useState(false);
  const [selectedUsage, setSelectedUsage] = useState("CPU Usage");
  const [showDaysDropdown, setShowDaysDropdown] = useState(false);

  const [selectedDay, setSelectedDay] = useState("7 Days");
  const usagesDropdownItems = ["CPU Usage", "Ram Usage1", "Disk Usage"];
  const dayDropdownItems = ["7 Days", "15 Days", "21 Days", "30 Days"];
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.dropdownContainer}>
          <Dropdown
            items={usagesDropdownItems}
            isActive={showUsageDropdown}
            setIsActive={setShowUsageDropdown}
            selectedValue={selectedUsage}
            onSelect={(val) => {
              setSelectedUsage(val);
            }}
          />
          <Dropdown
            items={dayDropdownItems}
            isActive={showDaysDropdown}
            setIsActive={setShowDaysDropdown}
            selectedValue={selectedDay}
            onSelect={(val) => {
              setSelectedDay(val);
            }}
          />
        </div>
        <Text textTeritary className={classes.info}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          consequat.
        </Text>
      </div>
      <Chart />
    </div>
  );
};

export default DetailsInChart;
