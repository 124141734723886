import React from "react";
import classes from "./Affiliate.module.css";
import { Button, Heading, Text, Counter } from "../../components/common";
import { checkmark } from "../../images";
import { FaAngleRight } from "react-icons/fa";

import { FiPlus } from "react-icons/fi";
const Affiliate = () => {
  const steps = [
    "Apply affiliate program",
    "Get your referral link",
    "VShare link and earn",
  ];
  const facilities = [
    {
      title: "Earn Passive Income",
      info: "Simply share your referral link and earn a 10% commission on every new user who signs up through your link.",
    },
    {
      title: "Community Benefits",
      info: "Join a community of referrers who are boosting their income and sharing their experiences.",
    },
    {
      title: "Easy to Track",
      info: "Our platform provides real-time tracking of your referrals and earnings.",
    },
    {
      title: "Unlimited Earning Potential",
      info: "There's no cap on how much you can earn through referrals.",
    },
  ];
  return (
    <div className={classes.wrapper}>
      <div className={classes.topPart}>
        <div className={classes.infoContainer}>
          <Heading xl5 className={classes.heading}>
            Get a 10% commission for each user you bring to [platform].
          </Heading>
          <Text secondary base>
            Join [platform] and start earning! Invite others to join using your
            unique referral link and earn a 10% commission for every new user
            who signs up.
          </Text>
        </div>
        <div className={classes.howItStart}>
          <Heading xl2>How to start</Heading>
          <div className={classes.steps}>
            {steps.map((step, id) => (
              <Text base textTeritary className={classes.step} key={id}>
                <img
                  src={checkmark}
                  alt="#"
                  className={classes.checkmarkIcon}
                />{" "}
                {step}
              </Text>
            ))}
          </div>
          <Button btnInfo onClick={() => {}} className={classes.button}>
            Apply Now
            <FaAngleRight />
          </Button>
        </div>
      </div>
      <div className={classes.facilitiesContainer}>
        {facilities.map((facility, i) => (
          <div className={classes.facility} key={i}>
            <Heading semiBold base>
              {facility.title}
            </Heading>
            <Text secondary>{facility.info}</Text>
          </div>
        ))}
      </div>
      <div className={classes.affiliateInfoContainer}>
        <div className={classes.affiliateInfo}>
          <Heading xl5 className={classes.number} textCenter>
            <Counter start={0} end={3000} durationTime={20} />
            <FiPlus className={classes.plusIcon} />
          </Heading>
          <Text base secondary textCenter>
            Affiliate Marketer
          </Text>
        </div>
        <div className={classes.affiliateInfo}>
          <Heading xl5 className={classes.number} textCenter>
            <Counter start={0} end={100} durationTime={20} />k
          </Heading>
          <Text base secondary textCenter>
            Link Shared
          </Text>
        </div>{" "}
        <div className={classes.affiliateInfo}>
          <Heading xl5 className={classes.number} textCenter>
            $<Counter start={0} end={1} durationTime={250} />M
            <FiPlus className={classes.plusIcon} />
          </Heading>
          <Text base secondary textCenter>
            Earned
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
